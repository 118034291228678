import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import withPageTransition from './withPageTransition';
import Home from '../pages/Home';
import About from '../pages/About';
import Memberships from '../pages/Memberships';
import Gallery from '../pages/Gallery';
import Contact from '../pages/Contact';
import Insights from '../pages/Insights';
import ArticleDetail from '../components/ArticleDetail';
import NotFound from '../pages/NotFound';
import Unsubscribe from '../pages/Unsuscribe';
import LinkUpComingSoon from '../pages/LinkUpComingSoon';
import usePageTitle from './usePageTitle';

const AnimatedRoutes = () => {
  const location = useLocation();
  usePageTitle('CCED');

  useEffect(() => {
    const pagePath = location.pathname + location.search;
    ReactGA.send({ hitType: "pageview", page: pagePath });
  }, [location]);

  // Wrap components with the HOC
  const HomeWithTransition = withPageTransition(Home);
  const AboutWithTransition = withPageTransition(About);
  const MembershipsWithTransition = withPageTransition(Memberships);
  const GalleryWithTransition = withPageTransition(Gallery);
  const ContactWithTransition = withPageTransition(Contact);
  const InsightsWithTransition = withPageTransition(Insights);
  const ArticleDetailWithTransition = withPageTransition(ArticleDetail);
  const NotFoundWithTransition = withPageTransition(NotFound);
  const UnsubscribeWithTransition = withPageTransition(Unsubscribe);
  const LinkUpComingSoonWithTransition = withPageTransition(LinkUpComingSoon);

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<HomeWithTransition />} />
        <Route path="/nosotros" element={<AboutWithTransition />} />
        <Route path="/membresias" element={<MembershipsWithTransition />} />
        <Route path="/galeria" element={<GalleryWithTransition />} />
        <Route path="/contacto" element={<ContactWithTransition />} />
        <Route path="/insights" element={<InsightsWithTransition />} />
        <Route path="/insights/:articleId/:articleTitle" element={<ArticleDetailWithTransition />} />
        <Route path="*" element={<NotFoundWithTransition />} />
        <Route path="/unsubscribe/confirm/:token" element={<UnsubscribeWithTransition />} />
        <Route path="/link-up" element={<LinkUpComingSoonWithTransition />} />
      </Routes>
    </AnimatePresence>
  );
};

export default AnimatedRoutes;
