import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

// Colores del CCED
const colors = {
  primary: "#5a6d86",
  secondary: "#9bc5d5",
  accent: "#597276",
  dark: "#32444e",
  deepDark: "#2b2227",
  light: "#d8ccb6",
  black: "#000000",
  white: "#ffffff",
};

/**
 * Unsubscribe component that handles the process of unsubscribing a user
 * based on a token provided in the URL. It displays a confirmation dialog
 * and processes the unsubscription request.
 */
const Unsubscribe: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const navigate = useNavigate();
  const [status, setStatus] = useState("");

  /**
   * Handles the unsubscription process by sending a request to the API
   * to update the user's subscription status.
   */
  const handleUnsubscribe = useCallback(async () => {
    try {
      console.log("Token recibido:", token);

      // Mostrar loading usando Swal
      MySwal.fire({
        title: "Procesando tu solicitud...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      // Realizar solicitud GET para obtener el suscriptor con el unsubscribe token
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/suscriptores?filters[unsubscribeToken][$eq]=${token}&fields[0]=id&fields[1]=unsubscribeToken`
      );
      const data = await response.json();

      console.log("Información del suscriptor:", data);

      if (data.data.length === 0) {
        console.log("Token de cancelación inválido.");
        setStatus("Token de cancelación inválido.");
        Swal.close();
        return;
      }

      const subscriberId = data.data[0].id;

      // Enviar solicitud para actualizar el estado de suscripción
      const updateResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/api/suscriptores/${subscriberId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            data: {
              isSubscribed: false,
            },
          }),
        }
      );

      if (!updateResponse.ok) {
        throw new Error(`Error HTTP! estado: ${updateResponse.status}`);
      }

      setStatus(
        "Tu suscripción ha sido cancelada exitosamente. ¡Esperamos verte de nuevo!"
      );
      Swal.fire({
        icon: "success",
        title: "Suscripción cancelada",
        text: "Lamentamos que te vayas. Tu suscripción ha sido cancelada exitosamente.",
        confirmButtonColor: colors.primary,
        customClass: {
          popup: "font-inter text-gray-800 bg-white",
          title: "text-lg font-bold text-gray-900",
          htmlContainer: "text-md text-gray-600",
          confirmButton: "bg-primary text-white",
        },
      }).then(() => {
        navigate("/");
      });
    } catch (error) {
      console.error("Error al cancelar la suscripción:", error);
      setStatus(
        "Hubo un error al procesar tu solicitud. Por favor, intenta nuevamente más tarde."
      );
      Swal.fire({
        icon: "error",
        title: "Error de conexión",
        text: "Hubo un error al procesar tu solicitud. Por favor, intenta nuevamente más tarde.",
        confirmButtonColor: colors.primary,
        customClass: {
          popup: "font-inter text-gray-800 bg-white",
          title: "text-lg font-bold text-gray-900",
          htmlContainer: "text-md text-gray-600",
          confirmButton: "bg-primary text-white",
        },
      });
    }
  }, [token, navigate]);

  useEffect(() => {
    const confirmUnsubscribe = () => {
      MySwal.fire({
        title: "¿Estás seguro que deseas cancelar tu suscripción?",
        text: "Dejarás de recibir contenido de primera mano...",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: colors.primary,
        cancelButtonColor: colors.deepDark,
        confirmButtonText: "Sí, cancelar suscripción",
        cancelButtonText: "No, mantener suscripción",
        customClass: {
          popup: "font-inter text-gray-800 bg-white",
          title: "text-lg font-bold text-gray-900",
          htmlContainer: "text-md text-gray-600",
          confirmButton: "bg-primary text-white",
          cancelButton: "bg-deepDark text-white",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          handleUnsubscribe();
        } else {
          navigate("/");
        }
      });
    };

    confirmUnsubscribe();
  }, [handleUnsubscribe, navigate]);

  return (
    <div id="unsubscribe-container" className="flex items-center justify-center h-screen">
      <div id="unsubscribe-content" className="bg-white p-8 rounded shadow-md text-center max-w-md w-full mx-4">
        <h1 id="unsubscribe-title" className="text-2xl font-bold text-gray-900 mb-4">
          Cancelar Suscripción
        </h1>
        <p id="unsubscribe-status" className="text-md text-gray-600">{status}</p>
      </div>
    </div>
  );
};

export default Unsubscribe;
