import React from 'react';

/**
 * Loader component to display a loading animation while content is being fetched.
 * 
 * This component shows a loading screen with three spinning circles in different directions.
 * It uses Tailwind CSS classes for styling and animation.
 * 
 * @component
 * @example
 * return (
 *   <Loader />
 * )
 */
const Loader: React.FC = () => {
  return (
    <div className="loader flex justify-center items-center min-h-screen bg-[#2B2227]" aria-live="polite" aria-busy="true">
      <div className="relative w-24 h-24" role="status">
        <div className="absolute inset-0 border-4 border-t-transparent border-[#597276] rounded-full animate-spin"></div>
        <div className="absolute inset-0 border-4 border-r-transparent border-[#5A6D86] rounded-full animate-spin-slow"></div>
        <div className="absolute inset-0 border-4 border-b-transparent border-[#D8CCB6] rounded-full animate-spin-reverse"></div>
      </div>
    </div>
  );
};

export default Loader;
