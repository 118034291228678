import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as solidHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as regularHeart } from '@fortawesome/free-regular-svg-icons';

interface LikeButtonProps {
  articleId: number;
  initialLikes: number;
}

const LikeButton = ({ articleId, initialLikes }: LikeButtonProps) => {
  const [likes, setLikes] = useState<number>(initialLikes);
  const [liked, setLiked] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [likeInProgress, setLikeInProgress] = useState<boolean>(false);

  useEffect(() => {
    const fetchLikes = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/articulos/${articleId}?populate=likes`
        );
        if (!response.ok) {
          throw new Error(`Error HTTP! estado: ${response.status}`);
        }
        const data = await response.json();
        const article = data.data.attributes;
        setLikes(article.likes);
        setLoading(false);
      } catch (error) {
        console.error("Error al obtener los likes:", error);
        setLoading(false);
      }
    };

    const localLiked = localStorage.getItem(`liked-${articleId}`);
    if (localLiked) {
      setLiked(true);
    }

    fetchLikes();
  }, [articleId]);

  const handleLike = async () => {
    if (likeInProgress) return; // Evitar múltiples clics mientras se procesa el like

    setLikeInProgress(true); // Indicar que se está procesando el like

    if (liked) {
      handleDislike();
      return;
    }

    try {
      // Obtener el artículo actual
      const getResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/api/articulos/${articleId}`
      );
      if (!getResponse.ok) {
        throw new Error(`Error HTTP! estado: ${getResponse.status}`);
      }
      const getData = await getResponse.json();
      const currentLikes = getData.data.attributes.likes;

      // Actualizar el número de likes
      const updateResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/api/articulos/${articleId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            data: {
              likes: currentLikes + 1,
            },
          }),
        }
      );

      if (!updateResponse.ok) {
        throw new Error(`Error HTTP! estado: ${updateResponse.status}`);
      }

      const updatedArticle = await updateResponse.json();
      setLikes(updatedArticle.data.attributes.likes);

      // Retrasar el cambio de estado liked para permitir la transición
      setTimeout(() => {
        setLiked(true);
        localStorage.setItem(`liked-${articleId}`, "true");
        setLikeInProgress(false); // Restablecer el estado después de la transición
      }, 300); // Tiempo igual al tiempo de transición
    } catch (error) {
      console.error("Error al dar like:", error);
      setLikeInProgress(false); // Restablecer el estado en caso de error
    }
  };

  const handleDislike = async () => {
    if (likeInProgress) return; // Evitar múltiples clics mientras se procesa el like

    setLikeInProgress(true); // Indicar que se está procesando el like

    try {
      // Obtener el artículo actual
      const getResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/api/articulos/${articleId}`
      );
      if (!getResponse.ok) {
        throw new Error(`Error HTTP! estado: ${getResponse.status}`);
      }
      const getData = await getResponse.json();
      const currentLikes = getData.data.attributes.likes;

      // Actualizar el número de likes
      const updateResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/api/articulos/${articleId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            data: {
              likes: currentLikes - 1,
            },
          }),
        }
      );

      if (!updateResponse.ok) {
        throw new Error(`Error HTTP! estado: ${updateResponse.status}`);
      }

      const updatedArticle = await updateResponse.json();
      setLikes(updatedArticle.data.attributes.likes);

      // Retrasar el cambio de estado liked para permitir la transición
      setTimeout(() => {
        setLiked(false);
        localStorage.removeItem(`liked-${articleId}`);
        setLikeInProgress(false); // Restablecer el estado después de la transición
      }, 300); // Tiempo igual al tiempo de transición
    } catch (error) {
      console.error("Error al quitar like:", error);
      setLikeInProgress(false); // Restablecer el estado en caso de error
    }
  };

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <button
      onClick={handleLike}
      className={`flex items-center space-x-2 bg-[#B9C5D5] text-[#2B2227] font-bold py-2 px-4 rounded-full transition duration-150 ease-in-out hover:bg-[#A1B3C2] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#B9C5D5] ${liked ? 'animate-pulse' : ''}`}
      style={{
        transition: 'color 0.3s ease-in-out' // Transición de color del ícono
      }}
    >
      <FontAwesomeIcon icon={liked ? solidHeart : regularHeart} className="text-red-500" />
      <span>{likes}</span>
    </button>
  );
};

export default LikeButton;
