
import { useLocation } from 'react-router-dom';
import Navbar from '../components/Navbar';

const Header = () => {
  const location = useLocation();


  const isGalleryPage = location.pathname === '/galeria';
  const isInsightsPage = location.pathname === '/insights';

  // Style object for conditional and constant styles
  const headerStyle = {
    transition: 'background-color 0.5s ease',
    boxShadow: isGalleryPage || isInsightsPage ? 'none' : '0px 2px 4px rgba(0, 0, 0, 0.1)'
  };

  return (
    <header className={`sticky top-0 z-50 ${isGalleryPage ? 'bg-transparent' : 'bg-[#2b2227] shadow-md'} ${isInsightsPage ? 'bg-transparent' : 'bg-[#2b2227] shadow-md'}`}
            style={headerStyle}>
      <Navbar />
    </header>
  );
};

export default Header;
