import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faPhoneAlt,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { motion, AnimatePresence } from "framer-motion";
import { useInView } from "react-intersection-observer";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

interface FAQ {
  question: string;
  answer: JSX.Element;
}
const FAQs: FAQ[] = [
  {
    question: "¿Qué es el CCED?",
    answer: (
      <p>
        El Centro de Conexión Estratégica y Directiva está dedicado a fomentar
        el crecimiento empresarial a través de programas de capacitación,
        sesiones de consejería multiempresa, recursos y herramientas
        especializadas y gestión de colaboraciones estratégicas, con el objetivo
        de impulsar el desarrollo económico regional.
      </p>
    ),
  },
  {
    question: "¿Por quiénes está compuesta nuestra comunidad?",
    answer: (
      <p>
        La comunidad del CCED está compuesta por empresarios, emprendedores,
        inversionistas, líderes, consultores y asesores comprometidos al
        crecimiento y al éxito. Somos una comunidad dinámica y colaborativa que
        reúne conocimientos que construirán el futuro de los negocios y la
        región. El propósito se centra en facilitar el crecimiento de los
        miembros, proporcionando un entorno para nutrir las ideas, fortalecer
        las habilidades y crear sinergias que creen un espacio de educación
        continua.
      </p>
    ),
  },
  {
    question: "¿Por qué formar parte de nuestra comunidad?",
    answer: (
      <p>
        A lo largo de la historia, los líderes han sido clave en el éxito o
        fracaso de los objetivos grupales, influyendo en el desarrollo y la
        economía de las sociedades. En CCED, nos enfocamos en formar líderes que
        impulsen el crecimiento de las organizaciones y la región. Ofrecemos{" "}
        <Link
          to="/#cursos-seminarios"
          className="hover:text-gray-400 underline"
        >
          cursos, talleres y consejería
        </Link>{" "}
        para mantener a nuestros miembros actualizados con{" "}
        <Link
          to="/#noticias-tendencias"
          className="hover:text-gray-400 underline"
        >
          noticias y tendencias
        </Link>
        , además de equiparlos con{" "}
        <Link to="/#herramientas" className="hover:text-gray-400 underline">
          herramientas, guías y plantillas
        </Link>{" "}
        para la toma de decisiones informadas. Gracias al{" "}
        <Link to="/#networking" className="hover:text-gray-400 underline">
          networking automatizado
        </Link>{" "}
        facilitamos la colaboración entre los miembros para crear alianzas
        estratégicas que fortalezcan el valor de las organizaciones.
      </p>
    ),
  },
  {
    question: "¿Cómo puedo participar en los cursos y capacitaciones?",
    answer: (
      <p>
        Los cursos y capacitaciones están disponibles para todos los miembros.
        Puedes consultar nuestro calendario CCED y registrarte para participar
        en los que sean de tu interés.
      </p>
    ),
  },
  {
    question: "¿Qué es el Consejo Multiempresa y cómo puedo participar?",
    answer: (
      <p>
        Es una unidad especializada que ofrece sesiones informativas que se
        llevan a cabo por expertos sobre temas específicos. Todos los miembros
        del CCED pueden participar en las sesiones programadas para compartir
        conocimientos y experiencias con los miembros interesados. Puedes
        consultar nuestro calendario CCED para registrarte a las sesiones.
      </p>
    ),
  },
  {
    question: "¿Cómo puedo facturar?",
    answer: (
      <p>
        Para facturar, te puedes poner en contacto con nuestro equipo por medio
        de nuestro correo electrónico{" "}
        <a
          href="mailto:contacto@cced.mx"
          className="hover:text-gray-400 underline"
        >
          contacto@cced.mx
        </a>{" "}
        o por medio de Whatsapp.
      </p>
    ),
  },
  {
    question: "¿Cómo puedo obtener más información sobre el CCED?",
    answer: (
      <p>
        Para obtener más información puedes explorar nuestro sitio web y redes
        sociales donde encontrarás detalles sobre nuestros programas, eventos,
        recursos y servicios. También, puedes ponerte en contacto con nuestro
        equipo a nuestro correo electrónico{" "}
        <a
          href="mailto:contacto@cced.mx"
          className="hover:text-gray-400 underline"
        >
          contacto@cced.mx
        </a>{" "}
        o por medio de Whatsapp.
      </p>
    ),
  },
];

/**
 * ContactPage component for displaying contact information and FAQs about the CCED.
 */
const ContactPage: React.FC = () => {
  const [openFAQIndex, setOpenFAQIndex] = useState<number | null>(null);
  const [formData, setFormData] = useState({
    name: "",
    lastname: "",
    position: "",
    phone: "",
    email: "",
    activity: "",
    message: "",
    privacy: false,
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type } = e.target;
    if (type === "checkbox") {
      const { checked } = e.target as HTMLInputElement;
      setFormData({
        ...formData,
        [name]: checked,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });

      // Remove highlight if the field is filled
      if (value) {
        document
          .getElementById(name)
          ?.classList.remove("border-red-500", "border-2");
      }
    }
  };

  const validateEmail = (email: string): boolean => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email);
  };

  const highlightIncompleteFields = () => {
    const fields: Array<keyof typeof formData> = [
      "name",
      "lastname",
      "position",
      "phone",
      "email",
      "activity",
      "message",
      "privacy",
    ];
    fields.forEach((field: keyof typeof formData) => {
      if (!formData[field]) {
        document
          .getElementById(field)
          ?.classList.add("border-red-500", "border-2");
      }
    });
  };

  const clearFormFields = () => {
    setFormData({
      name: "",
      lastname: "",
      position: "",
      phone: "",
      email: "",
      activity: "",
      message: "",
      privacy: false,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Validate form fields
    if (
      !formData.name ||
      !formData.lastname ||
      !formData.position ||
      !formData.phone ||
      !formData.email ||
      !formData.activity ||
      !formData.message
    ) {
      highlightIncompleteFields();
      MySwal.fire({
        icon: "warning",
        title: "Campos incompletos",
        text: "Por favor, complete todos los campos del formulario.",
        confirmButtonColor: "#5a6d86",
      });
      return;
    }

    // Validate email format
    if (!validateEmail(formData.email)) {
      MySwal.fire({
        icon: "warning",
        title: "Correo electrónico inválido",
        text: "Por favor, ingrese un correo electrónico válido.",
        confirmButtonColor: "#5a6d86",
      });
      return;
    }

    // Validate privacy policy checkbox
    if (!formData.privacy) {
      MySwal.fire({
        icon: "warning",
        title: "Política de Privacidad",
        text: "Debe aceptar la Política de Privacidad para continuar.",
        confirmButtonColor: "#5a6d86",
      });
      return;
    }

    try {
      const response = await axios.post(
        "https://cced.mx/api/send-email",
        formData
      );
      if (response.status === 200) {
        MySwal.fire({
          icon: "success",
          title: "Mensaje enviado",
          text: "Su mensaje ha sido enviado exitosamente. Espere pronto una respuesta de nuestro equipo.",
          confirmButtonColor: "#5a6d86",
        });
        clearFormFields();
      } else {
        MySwal.fire({
          icon: "error",
          title: "Error",
          text: "Hubo un error al enviar el mensaje. Intente nuevamente más tarde.",
          confirmButtonColor: "#5a6d86",
        });
      }
    } catch (error) {
      console.error("Error al enviar el mensaje:", error);
      MySwal.fire({
        icon: "error",
        title: "Error",
        text: "Hubo un error al enviar el mensaje. Intente nuevamente más tarde.",
        confirmButtonColor: "#5a6d86",
      });
    }
  };

  /**
   * Toggle the visibility of an FAQ item.
   * @param {number} index - The index of the FAQ item to toggle.
   */
  const toggleFAQ = (index: number) => {
    setOpenFAQIndex(openFAQIndex === index ? null : index);
  };

  // Intersection Observers
  const { ref: faqTitleRef, inView: faqTitleInView } = useInView({
    triggerOnce: true,
  });
  const { ref: faqItemRef, inView: faqItemInView } = useInView({
    triggerOnce: true,
  });
  const { ref: contactFormRef, inView: contactFormInView } = useInView({
    triggerOnce: true,
  });
  const { ref: contactInfoRef, inView: contactInfoInView } = useInView({
    triggerOnce: true,
  });
  const { ref: denunciaRef, inView: denunciaInView } = useInView({
    triggerOnce: true,
  });

  return (
    <div>
      <section className="flex flex-col md:flex-col lg:flex-row">
        <div
          id="contact-background-section"
          className="relative w-full lg:w-1/2"
        >
          <picture className="w-full h-auto lg:h-full object-cover">
            <source
              srcSet="
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716600795/contact_bg_4b329699ad.webp 480w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716600795/contact_bg_4b329699ad.webp 800w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716600795/contact_bg_4b329699ad.webp 1200w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1716600795/contact_bg_4b329699ad.webp 1600w"
              sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
              type="image/webp"
            />
            <source
              srcSet="
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716600795/contact_bg_4b329699ad.png 480w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716600795/contact_bg_4b329699ad.png 800w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/w_1200/v1716600795/contact_bg_4b329699ad.png 1200w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/w_1600/v1716600795/contact_bg_4b329699ad.png 1600w"
              sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
              type="image/png"
            />
            <img
              src="https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/v1716600795/contact_bg_4b329699ad.png"
              alt="Background"
              className="w-full h-auto lg:h-full object-cover"
              loading="lazy"
              id="contact-background-image"
            />
          </picture>

          <div className="absolute inset-0 bg-gradient-to-b from-neutral-800 via-transparent"></div>
        </div>

        <div className="w-full lg:w-1/2 bg-[#2B2227] px-4 sm:px-8 md:px-16 lg:px-24 pt-10 md:pt-20 lg:pt-40">
          <motion.div
            className="mb-6 p-4 sm:p-8 md:p-12 bg-[rgba(50,68,78,0.5)] rounded-3xl"
            ref={contactFormRef}
            initial={{ opacity: 0, y: 50 }}
            animate={contactFormInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.8, ease: "easeOut" }}
          >
            <h1 className="text-2xl sm:text-3xl md:text-3xl font-bold mb-4 sm:mb-6 ml-2 sm:ml-4 md:ml-6 text-white">
              Contáctanos
            </h1>

            <form className="text-white" onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div className="mb-3">
                  <label htmlFor="name" className="sr-only">
                    Nombre
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Nombre"
                    className="w-full border rounded-3xl py-2 sm:py-3 px-3 sm:px-5 bg-transparent leading-tight focus:outline-none focus:shadow-outline placeholder-gray-100 placeholder-opacity-100"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="lastname" className="sr-only">
                    Apellido
                  </label>
                  <input
                    type="text"
                    id="lastname"
                    name="lastname"
                    placeholder="Apellido"
                    className="w-full border rounded-3xl py-2 sm:py-3 px-3 sm:px-5 bg-transparent leading-tight focus:outline-none focus:shadow-outline placeholder-gray-100 placeholder-opacity-100"
                    value={formData.lastname}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="position" className="sr-only">
                    Empresa
                  </label>
                  <input
                    type="text"
                    id="position"
                    name="position"
                    placeholder="Empresa"
                    className="w-full border rounded-3xl py-2 sm:py-3 px-3 sm:px-5 bg-transparent leading-tight focus:outline-none focus:shadow-outline placeholder-gray-100 placeholder-opacity-100"
                    value={formData.position}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="phone" className="sr-only">
                    Teléfono
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    placeholder="Teléfono"
                    className="w-full border rounded-3xl py-2 sm:py-3 px-3 sm:px-5 bg-transparent leading-tight focus:outline-none focus:shadow-outline placeholder-gray-100 placeholder-opacity-100"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="sr-only">
                  Correo electrónico
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Correo electrónico"
                  className="w-full border rounded-3xl py-2 sm:py-3 px-3 sm:px-5 bg-transparent leading-tight focus:outline-none focus:shadow-outline placeholder-gray-100 placeholder-opacity-100"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="activity" className="sr-only">
                  Actividad económica
                </label>
                <input
                  type="text"
                  id="activity"
                  name="activity"
                  placeholder="Actividad económica"
                  className="w-full border rounded-3xl py-2 sm:py-3 px-3 sm:px-5 bg-transparent leading-tight focus:outline-none focus:shadow-outline placeholder-gray-100 placeholder-opacity-100"
                  value={formData.activity}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="message" className="sr-only">
                  Mensaje
                </label>
                <textarea
                  id="message"
                  name="message"
                  placeholder="Mensaje"
                  rows={4}
                  className="w-full border rounded-3xl py-3 sm:py-5 px-3 sm:px-5 bg-transparent leading-tight focus:outline-none focus:shadow-outline placeholder-gray-100 placeholder-opacity-100"
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
              </div>

              <div className="mb-4 flex items-center">
                <input
                  type="checkbox"
                  id="privacy"
                  name="privacy"
                  className="mr-2"
                  checked={formData.privacy}
                  onChange={handleChange}
                />
                <label htmlFor="privacy">
                  He leído y acepto la{" "}
                  <span className="underline">Política de Privacidad</span>
                </label>
              </div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="bg-[#B9C5D5] text-[#2B2227] font-bold py-2 mb-2 px-4 rounded-full focus:outline-none focus:shadow-outline hover:bg-gray-800 hover:text-white transition duration-300 ease-in-out"
                >
                  Enviar
                </button>
              </div>
            </form>
          </motion.div>
        </div>
      </section>

      <section className="bg-[#597276] text-white text-2xl px-4 md:px-10 lg:px-60 py-6 md:py-10 lg:py-14 flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0">
        <motion.div
          className="flex items-center justify-center md:justify-start w-full md:w-1/3"
          ref={contactInfoRef}
          initial={{ opacity: 0, y: 50 }}
          animate={contactInfoInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
          <a
            href="https://www.google.com.mx/maps/place/Av.+San+Luis+Potos%C3%AD+63A,+San+Benito,+83190+Hermosillo,+Son./@29.0906359,-110.9597195,17z/data=!3m1!4b1!4m6!3m5!1s0x86ce8436af479131:0x20b9fcbb7456ac1f!8m2!3d29.0906312!4d-110.9571446!16s%2Fg%2F11c5kzk7fz?entry=tts"
            className="text-sm underline text-center md:text-left"
            target="_blank"
            rel="noopener noreferrer"
          >
            Av. San Luis Potosí 63A, San Benito, 83190 Hermosillo, Son.
          </a>
        </motion.div>
        <motion.div
          className="flex flex-col items-center justify-center md:justify-center w-full md:w-1/3"
          ref={contactInfoRef}
          initial={{ opacity: 0, y: 50 }}
          animate={contactInfoInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          <span className="text-sm text-center">
            <FontAwesomeIcon icon={faPhoneAlt} className="mr-2" />
            Tel. (662) 2-15-56-39 y
          </span>
          <span className="text-sm text-center">(662) 2-15-54-26</span>
        </motion.div>
        <motion.div
          className="flex items-center justify-center md:justify-end w-full md:w-1/3"
          ref={contactInfoRef}
          initial={{ opacity: 0, y: 50 }}
          animate={contactInfoInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
          <a
            href="mailto:contacto@cced.mx"
            className="text-sm text-center md:text-right"
          >
            contacto@cced.mx
          </a>
        </motion.div>
      </section>

      <section
        id="faq"
        className="relative flex flex-wrap bg-[#2B2227] text-white"
      >
        <div className="relative flex-1 w-full md:w-2/3 p-8 md:p-24 z-10">
          <motion.h2
            className="text-2xl font-bold mb-6 text-center md:text-left"
            ref={faqTitleRef}
            initial={{ opacity: 0, y: 50 }}
            animate={faqTitleInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.8, ease: "easeOut" }}
          >
            Preguntas frecuentes
          </motion.h2>
          {FAQs.map((faq, index) => (
            <div key={index} className="mb-4 pb-4 border-b border-gray-200">
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => toggleFAQ(index)}
              >
                <motion.h3
                  className="text-lg font-bold flex-grow"
                  ref={faqItemRef}
                  initial={{ opacity: 0, y: 50 }}
                  animate={faqItemInView ? { opacity: 1, y: 0 } : {}}
                  transition={{ duration: 0.8, ease: "easeOut" }}
                >
                  {faq.question}
                </motion.h3>
                <button className="text-xl px-4 font-bold">
                  {openFAQIndex === index ? "-" : "+"}
                </button>
              </div>
              <AnimatePresence>
                {openFAQIndex === index && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                    className="mt-2"
                  >
                    {faq.answer}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          ))}
        </div>
        <div className="hidden lg:flex flex-1 w-1/3 justify-center md:justify-end items-center">
          <img
            src="/static/contact-faq-bg.png"
            alt="Descriptive Alt Text"
            className="w-full h-auto object-contain"
            loading="lazy"
          />
        </div>

        <div className="absolute inset-0 lg:hidden flex justify-end">
          <img
            src="/static/contact-faq-bg.png"
            alt="Descriptive Alt Text"
            className="w-full h-full object-contain"
            loading="lazy"
          />
        </div>
      </section>

      <motion.section
        className="bg-[#F7F7F7] py-12 flex flex-col md:flex-row justify-center items-center rounded-lg shadow-md"
        ref={denunciaRef}
        initial={{ opacity: 0, y: 50 }}
        animate={denunciaInView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.8, ease: "easeOut" }}
      >
        <div className="flex items-center justify-center mb-4 md:mb-0 md:mr-16 px-8 md:px-0">
          <img
            src="/static/denuncias-img2.png"
            alt="Feedback Icon"
            className="w-24 md:w-32"
            loading="lazy"
          />
        </div>
        <div className="text-center md:text-left px-8 md:px-0">
          <h2 className="text-lg font-extrabold text-[#FF4F5B] pb-2">
            ¡AYÚDENOS A MEJORAR!
          </h2>
          <p className="font-extrabold pb-2">
            ¿Tiene alguna denuncia, queja o sugerencia para CCED?
          </p>
          <p className="pb-2">
            Accede a nuestro{" "}
            <span className="font-bold">Canal de Denuncias</span>
          </p>
          <p className="text-sm pb-2">
            En CCED garantizamos un ambiente seguro, confiable y saludable.
          </p>
        </div>
      </motion.section>
    </div>
  );
};

export default ContactPage;
