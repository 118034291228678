import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

interface Article {
  id: number;
  title: string;
  content: string;
  publishedAt: string;
  image?: {
    url: string;
  };
  author: {
    name: string;
    image?: {
      url: string;
    };
  };
  tags: {
    name: string;
  }[];
}

/**
 * Truncate a given markdown text to a specified character limit.
 * 
 * @param markdownText The markdown text to truncate.
 * @param limit The maximum number of characters.
 * @returns The truncated markdown text.
 */
function truncateMarkdown(markdownText: string, limit: number) {
  let currentCount = 0;
  let result = "";
  const lines = markdownText.split("\n");

  for (let line of lines) {
    if (line.trim().startsWith("![")) continue;
    if (currentCount >= limit) break;

    if (currentCount + line.length > limit) {
      let remaining = limit - currentCount;
      result += line.substring(0, remaining) + "...";
      break;
    } else {
      result += line + "\n";
      currentCount += line.length;
    }
  }

  return result;
}

const ArticleCard: React.FC<{ article: Article }> = ({ article }) => {
  /**
   * Format the article title to be URL-friendly.
   * 
   * @param title The title of the article.
   * @returns The formatted title.
   */
  const formatTitleForUrl = (title: string) =>
    title
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");

  const truncatedContent = truncateMarkdown(article.content, 100);

  /**
   * Get the full URL for the image.
   * 
   * @param url The relative or absolute URL of the image.
   * @returns The full URL of the image.
   */
  const getImageUrl = (url: string) => {
    const params = new URLSearchParams({ f_auto: 'true', q_auto: 'true' });
    if (url.startsWith("http")) {
      return `${url}?${params.toString()}`;
    }
    return `${process.env.REACT_APP_API_URL}${url}?${params.toString()}`;
  };

  return (
    <Link
      to={`/insights/${article.id}/${formatTitleForUrl(article.title)}`}
      className="article-link group m-4 block transition duration-300 ease-in-out transform hover:scale-105"
    >
      <article key={article.id} className="overflow-hidden shadow-lg hover:shadow-2xl">
        {article.image ? (
          <picture className="w-full h-60 object-cover transition duration-300 ease-in-out group-hover:opacity-90">
            <source
              srcSet={`
                ${getImageUrl(article.image.url)}?format=webp&w=480 480w,
                ${getImageUrl(article.image.url)}?format=webp&w=800 800w,
                ${getImageUrl(article.image.url)}?format=webp&w=1200 1200w,
                ${getImageUrl(article.image.url)}?format=webp&w=1600 1600w
              `}
              sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
              type="image/webp"
            />
            <source
              srcSet={`
                ${getImageUrl(article.image.url)}?w=480 480w,
                ${getImageUrl(article.image.url)}?w=800 800w,
                ${getImageUrl(article.image.url)}?w=1200 1200w,
                ${getImageUrl(article.image.url)}?w=1600 1600w
              `}
              sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
              type="image/jpeg"
            />
            <img
              src={getImageUrl(article.image.url)}
              alt="Cover"
              className="w-full h-60 object-cover"
              loading="lazy"
            />
          </picture>
        ) : (
          <div className="h-60 bg-gray-200 flex items-center justify-center">
            <p className="text-gray-500">No image available</p>
          </div>
        )}
        <header className="flex pt-2 items-center">
          {article.author.image ? (
            <picture className="w-12 h-12 rounded-full mr-3 ml-3">
              <source
                srcSet={`
                  ${getImageUrl(article.author.image.url)}?format=webp&w=48 48w,
                  ${getImageUrl(article.author.image.url)}?format=webp&w=96 96w
                `}
                sizes="48px"
                type="image/webp"
              />
              <source
                srcSet={`
                  ${getImageUrl(article.author.image.url)}?w=48 48w,
                  ${getImageUrl(article.author.image.url)}?w=96 96w
                `}
                sizes="48px"
                type="image/jpeg"
              />
              <img
                src={getImageUrl(article.author.image.url)}
                alt="Author"
                className="w-12 h-12 rounded-full"
                loading="lazy"
              />
            </picture>
          ) : (
            <div className="w-12 h-12 bg-gray-300 rounded-full mr-3"></div>
          )}
          <span className="font-medium text-[#B9C5D5]">{article.author.name}</span>
          <p className="text-sm text-[#B9C5D5] ml-3">
            / Publicado el: {new Date(article.publishedAt).toLocaleDateString()}
          </p>
        </header>
  
        <section className="p-2">
          <h2 className="text-2xl text-white font-semibold mb-2">{article.title}</h2>
          <div className="text-[#D8CCB6] mb-4">
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              children={truncatedContent}
            />
          </div>
          <div className="flex flex-wrap mb-4">
            {article.tags.map((tag, index) => (
              <span
                key={index}
                className="bg-[#B9C5D5] text-gray-800 text-sm font-semibold mr-2 mb-2 px-2.5 py-0.5 rounded"
              >
                {tag.name}
              </span>
            ))}
          </div>
        </section>
      </article>
    </Link>
  );
  
};

export default ArticleCard;
