import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faBars } from "@fortawesome/free-solid-svg-icons";
import { motion, AnimatePresence } from "framer-motion";
import { useInView } from "react-intersection-observer";

/**
 * Navbar component that displays a responsive navigation bar.
 */
const Navbar: React.FC = () => {
  // State to manage the menu's open/closed state
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // React Router hook to get the current location
  const location = useLocation();

  /**
   * Toggle the menu open/closed state
   */
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Labels for navigation links
  const linkLabels = {
    "/": "Inicio",
    "/nosotros": "Nosotros",
    "/membresias": "Membresías",
    "/galeria": "Galería",
    "/insights": "Insights",
    "/contacto": "Contacto",
  };

  // Animation variants for the menu
  const menuVariants = {
    open: {
      height: "auto",
      opacity: 1,
      transition: { duration: 0.5, ease: "easeInOut" },
    },
    closed: {
      height: 0,
      opacity: 0,
      transition: { duration: 0.5, ease: "easeInOut" },
    },
  };

  /**
   * Generate class names for links based on the current path
   * @param {string} path - The path of the link
   * @returns {string} - The class names for the link
   */
  const linkClassNames = (path: string) => {
    const isActive =
      location.pathname === path ||
      (path === "/insights" && location.pathname.startsWith("/insights")) ||
      (path === "/link-up" && location.pathname === "/link-up");
    return `inline-block text-sm px-4 py-2 leading-none rounded mt-4 lg:mt-0 transition-colors transition-transform duration-300 ease-in-out ${
      isActive
        ? "bg-[rgba(185,197,213,0.2)] text-[#d8ccb6] font-bold hover:scale-105"
        : "text-white hover:text-[#d8ccb6]"
    }`;
  };

  // Intersection observer to trigger animations
  const { ref } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <nav
      id="main-nav"
      className={`pb-6 pt-6 flex items-center justify-between flex-wrap text-base ${
        isMenuOpen ? "bg-[#2b2227]" : ""
      }`}
      ref={ref}
      role="navigation"
      aria-label="Main navigation"
    >
      <div
        id="logo-container"
        className="flex items-center px-10 flex-shrink-0 mr-6"
      >
        <img
          src="/static/logov2.png"
          alt="Nombre de tu empresa"
          className="h-10 w-auto"
          loading="lazy"
        />
      </div>

      <div id="mobile-menu-button" className="block px-10 lg:hidden">
        <button
          onClick={toggleMenu}
          className="flex items-center px-3 py-2 border rounded text-white border-white hover:text-[#2b2227] hover:border-[#2b2227]"
          aria-label="Toggle menu"
        >
          <FontAwesomeIcon icon={faBars} />
        </button>
      </div>

      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            id="mobile-menu"
            initial="closed"
            animate="open"
            exit="closed"
            variants={menuVariants}
            className="w-full block bg-[#2b2227] flex-grow lg:hidden"
          >
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.5 }}
              className="w-full flex px-10 flex-col items-center"
            >
              {Object.entries(linkLabels).map(([path, label]) => (
                <Link
                  key={path}
                  to={path}
                  className={linkClassNames(path)}
                  onClick={() => setIsMenuOpen(false)}
                  id={`link-${path}`}
                >
                  {label}
                </Link>
              ))}
              <Link
                to="/link-up"
                className={linkClassNames("/link-up")}
                onClick={() => setIsMenuOpen(false)}
                id="link-link-up"
              >
                <FontAwesomeIcon icon={faUser} className="mr-2" />
                Link Up
              </Link>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <div
        id="desktop-menu"
        className="hidden lg:flex lg:items-center lg:w-auto"
      >
        {Object.entries(linkLabels).map(([path, label]) => (
          <Link
            key={path}
            to={path}
            className={linkClassNames(path)}
            id={`link-${path}`}
          >
            {label}
          </Link>
        ))}
      </div>

      <div
        id="desktop-link-up"
        className="hidden lg:flex items-center w-full lg:w-auto flex-col lg:flex-row justify-center lg:justify-end mr-10"
      >
        <Link
          to="/link-up"
          className={linkClassNames("/link-up")}
          id="link-link-up-desktop"
        >
          <FontAwesomeIcon icon={faUser} className="mr-2" />
          Link Up
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
