import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as solidHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as regularHeart } from '@fortawesome/free-regular-svg-icons';

interface CommentLikeButtonProps {
  commentId: number;
  initialLikes: number;
}

const CommentLikeButton = ({ commentId, initialLikes }: CommentLikeButtonProps) => {
  const [likes, setLikes] = useState<number>(initialLikes);
  const [liked, setLiked] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [likeInProgress, setLikeInProgress] = useState<boolean>(false);

  useEffect(() => {
    const fetchLikes = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/comments/${commentId}?populate=likes`
        );
        if (!response.ok) {
          throw new Error(`Error HTTP! estado: ${response.status}`);
        }
        const data = await response.json();
        const comment = data.data.attributes;
        setLikes(comment.likes);
        setLoading(false);
      } catch (error) {
        console.error("Error al obtener los likes:", error);
        setLoading(false);
      }
    };

    const localLiked = localStorage.getItem(`liked-${commentId}`);
    if (localLiked) {
      setLiked(true);
    }

    fetchLikes();
  }, [commentId]);

  const handleLike = async () => {
    if (likeInProgress) return;

    setLikeInProgress(true);

    if (liked) {
      await handleDislike();
      return;
    }

    try {
      const getResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/api/comments/${commentId}`
      );
      if (!getResponse.ok) {
        throw new Error(`Error HTTP! estado: ${getResponse.status}`);
      }
      const getData = await getResponse.json();
      const currentLikes = getData.data.attributes.likes;

      const updateResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/api/comments/${commentId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            data: {
              likes: currentLikes + 1,
            },
          }),
        }
      );

      if (!updateResponse.ok) {
        const errorData = await updateResponse.json();
        throw new Error(`Error HTTP! estado: ${updateResponse.status}, detalles: ${JSON.stringify(errorData)}`);
      }

      const updatedComment = await updateResponse.json();
      setLikes(updatedComment.data.attributes.likes);

      setTimeout(() => {
        setLiked(true);
        localStorage.setItem(`liked-${commentId}`, "true");
        setLikeInProgress(false);
      }, 300);
    } catch (error) {
      console.error("Error al dar like:", error);
      setLikeInProgress(false);
    }
  };

  const handleDislike = async () => {
    if (likeInProgress) return;

    setLikeInProgress(true);

    try {
      const getResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/api/comments/${commentId}`
      );
      if (!getResponse.ok) {
        throw new Error(`Error HTTP! estado: ${getResponse.status}`);
      }
      const getData = await getResponse.json();
      const currentLikes = getData.data.attributes.likes;

      if (currentLikes > 0) {
        const updateResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/api/comments/${commentId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify({
              data: {
                likes: currentLikes - 1,
              },
            }),
          }
        );

        if (!updateResponse.ok) {
          const errorData = await updateResponse.json();
          throw new Error(`Error HTTP! estado: ${updateResponse.status}, detalles: ${JSON.stringify(errorData)}`);
        }

        const updatedComment = await updateResponse.json();
        setLikes(updatedComment.data.attributes.likes);

        setTimeout(() => {
          setLiked(false);
          localStorage.removeItem(`liked-${commentId}`);
          setLikeInProgress(false);
        }, 300);
      } else {
        setLikeInProgress(false);
      }
    } catch (error) {
      console.error("Error al quitar like:", error);
      setLikeInProgress(false);
    }
  };

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <button
      onClick={handleLike}
      className={`flex items-center space-x-2 bg-[#B9C5D5] text-[#2B2227] font-bold py-2 px-4 rounded-full transition duration-150 ease-in-out hover:bg-[#A1B3C2] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#B9C5D5] ${liked ? 'animate-pulse' : ''}`}
      style={{
        transition: 'color 0.3s ease-in-out' // Transición de color del ícono
      }}
    >
      <FontAwesomeIcon icon={liked ? solidHeart : regularHeart} className="text-red-500" />
      <span>{likes}</span>
    </button>
  );
};

export default CommentLikeButton;
