import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { motion, AnimatePresence } from "framer-motion";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

/**
 * Footer component that includes social media links, navigation links,
 * a subscription form, and a scroll-to-top button.
 */
const Footer: React.FC = () => {
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [formData, setFormData] = useState({ name: "", email: "" });
  const [isPrivacyChecked, setIsPrivacyChecked] = useState(false);

  /**
   * Handle scroll event to show or hide the scroll-to-top button
   */
  const handleScroll = () => {
    if (window.pageYOffset > 300) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };

  /**
   * Scroll smoothly to the top of the page
   */
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  /**
   * Handle input changes in the subscription form
   */
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  /**
   * Handle checkbox changes in the subscription form
   */
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsPrivacyChecked(event.target.checked);
  };


  /**
   * Handle form submission for the subscription form
   */
  const MySwal = withReactContent(Swal);
  // Colores del CCED
  const colors = {
    primary: "#5a6d86",
    secondary: "#9bc5d5",
    accent: "#597276",
    dark: "#32444e",
    deepDark: "#2b2227",
    light: "#d8ccb6",
    black: "#000000",
    white: "#ffffff",
  };

  // Función handleSubmit
  // Función handleSubmit
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isPrivacyChecked) {
      MySwal.fire({
        icon: "warning",
        title: "Aviso de privacidad",
        text: "Por favor, acepta el aviso de privacidad para continuar con tu suscripción.",
        confirmButtonColor: colors.primary,
        customClass: {
          popup: "font-inter text-gray-800 bg-white",
          title: "text-lg font-bold text-gray-900",
          htmlContainer: "text-md text-gray-600",
          confirmButton: "bg-primary text-white",
        },
      });
      return;
    }

    // Validar si el correo ya está registrado
    try {
      const existingResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/api/suscriptores?filters[Email][$eq]=${formData.email}`
      );
      const existingSubscribers = await existingResponse.json();

      if (existingSubscribers.data.length > 0) {
        const subscriber = existingSubscribers.data[0];
        if (subscriber.attributes.isSubscribed === false) {
          const result = await MySwal.fire({
            title: "Bienvenido de vuelta",
            text: "Parece que ya te has suscrito anteriormente con este correo electrónico pero la suscripción está inactiva. ¿Deseas reactivarla?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: colors.primary,
            cancelButtonColor: colors.deepDark,
            confirmButtonText: "Sí, reactivarla",
            cancelButtonText: "Cancelar",
            customClass: {
              popup: "font-inter text-gray-800 bg-white",
              title: "text-lg font-bold text-gray-900",
              htmlContainer: "text-md text-gray-600",
              confirmButton: "bg-primary text-white",
              cancelButton: "bg-deepDark text-white",
            },
          });

          if (result.isConfirmed) {
            const requestBody = JSON.stringify({
              data: {
                isSubscribed: true,
              },
            });

            const updateResponse = await fetch(
              `${process.env.REACT_APP_API_URL}/api/suscriptores/${subscriber.id}`,
              {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                },
                body: requestBody,
              }
            );

            if (!updateResponse.ok) {
              throw new Error(`Error HTTP! estado: ${updateResponse.status}`);
            }

            MySwal.fire({
              icon: "success",
              title: "¡Bienvenido de vuelta!",
              text: "Tu suscripción ha sido reactivada exitosamente.",
              confirmButtonColor: colors.primary,
              customClass: {
                popup: "font-inter text-gray-800 bg-white",
                title: "text-lg font-bold text-gray-900",
                htmlContainer: "text-md text-gray-600",
                confirmButton: "bg-primary text-white",
              },
            });

            // Restablecer el formulario después del envío exitoso
            setFormData({ name: "", email: "" });
            setIsPrivacyChecked(false);
            return;
          } else {
            return;
          }
        } else {
          MySwal.fire({
            icon: "error",
            title: "Correo ya registrado",
            text: "Parece que ya te has suscrito anteriormente con este correo electrónico. ¡Gracias por tu interés continuo!",
            confirmButtonColor: colors.primary,
            customClass: {
              popup: "font-inter text-gray-800 bg-white",
              title: "text-lg font-bold text-gray-900",
              htmlContainer: "text-md text-gray-600",
              confirmButton: "bg-primary text-white",
            },
          });
          return;
        }
      }
    } catch (error) {
      console.error("Error al verificar el correo:", error);
      MySwal.fire({
        icon: "error",
        title: "Error de conexión",
        text: "No se pudo verificar el correo electrónico. Por favor, intenta nuevamente más tarde.",
        confirmButtonColor: colors.primary,
        customClass: {
          popup: "font-inter text-gray-800 bg-white",
          title: "text-lg font-bold text-gray-900",
          htmlContainer: "text-md text-gray-600",
          confirmButton: "bg-primary text-white",
        },
      });
      return;
    }

    // Modal de confirmación
    const result = await MySwal.fire({
      title: "Confirmar suscripción",
      text: "¿Estás listo para unirte a nuestra comunidad y recibir nuestras últimas actualizaciones?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: colors.primary,
      cancelButtonColor: colors.deepDark,
      confirmButtonText: "Sí, suscribirme",
      cancelButtonText: "Cancelar",
      customClass: {
        popup: "font-inter text-gray-800 bg-white",
        title: "text-lg font-bold text-gray-900",
        htmlContainer: "text-md text-gray-600",
        confirmButton: "bg-primary text-white",
        cancelButton: "bg-deepDark text-white",
      },
    });

    if (result.isConfirmed) {
      try {
        const requestBody = JSON.stringify({
          data: {
            nombre: formData.name,
            Email: formData.email,
            isSubscribed: true,
          },
        });

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/suscriptores`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: requestBody,
          }
        );

        if (!response.ok) {
          throw new Error(`Error HTTP! estado: ${response.status}`);
        }

        const data = await response.json();

        if (data.error) {
          MySwal.fire({
            icon: "error",
            title: "Error en la suscripción",
            text: data.error.message,
            confirmButtonColor: colors.primary,
            customClass: {
              popup: "font-inter text-gray-800 bg-white",
              title: "text-lg font-bold text-gray-900",
              htmlContainer: "text-md text-gray-600",
              confirmButton: "bg-primary text-white",
            },
          });
        } else {
          const subscriberId = data.data.id;
          MySwal.fire({
            icon: "success",
            title: "¡Bienvenido a la familia CCED!",
            html: `Gracias por unirte a nosotros, <strong>${formData.name}</strong>. Revisa tu bandeja de entrada o correos no deseados para nuestras actualizaciones. Recuerda que siempre puedes <a href="${process.env.REACT_APP_API_URL}/unsubscribe/${subscriberId}" class="text-primary font-bold">cancelar tu suscripción aquí</a>.`,
            confirmButtonColor: colors.primary,
            customClass: {
              popup: "font-inter text-gray-800 bg-white",
              title: "text-lg font-bold text-gray-900",
              htmlContainer: "text-md text-gray-600",
              confirmButton: "bg-primary text-white",
            },
          });
          // Restablecer el formulario después del envío exitoso
          setFormData({ name: "", email: "" });
          setIsPrivacyChecked(false);
        }
      } catch (error) {
        MySwal.fire({
          icon: "error",
          title: "Error de conexión",
          text: "No se pudo conectar con el servidor. Por favor, intenta nuevamente más tarde.",
          confirmButtonColor: colors.primary,
          customClass: {
            popup: "font-inter text-gray-800 bg-white",
            title: "text-lg font-bold text-gray-900",
            htmlContainer: "text-md text-gray-600",
            confirmButton: "bg-primary text-white",
          },
        });
        console.error("Error:", error);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isLargeScreen, setIsLargeScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1024);
    };

    handleResize(); // Set initial state
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <footer className="bg-[#32444E] pt-4 text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex flex-wrap justify-between items-start">
          {/* Logo and social media links */}
          <div className="w-full md:w-1/2 lg:w-1/4 px-4 mb-4 md:mb-0">
            <p className="block mb-4">
              <img
                src="/static/logov2.png"
                alt="Logo"
                className="h-10"
                loading="lazy"
              />
            </p>

            <div className="flex pt-8 justify-start space-x-4">
              <a
                href="https://www.facebook.com/profile.php?id=61557296489814"
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#32444E] bg-white rounded-full p-2 flex items-center justify-center w-10 h-10 transition duration-300 ease-in-out transform hover:bg-[#32444E] hover:text-white hover:scale-110"
              >
                <FontAwesomeIcon icon={faFacebookF} size="lg" />
              </a>
              <a
                href="https://www.instagram.com/ccedmx/?next=%2F"
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#32444E] bg-white rounded-full p-2 flex items-center justify-center w-10 h-10 transition duration-300 ease-in-out transform hover:bg-[#32444E] hover:text-white hover:scale-110"
              >
                <FontAwesomeIcon icon={faInstagram} size="lg" />
              </a>
              <a
                href="http://www.linkedin.com/in/centro-de-conexi%C3%B3n-estrat%C3%A9gica-y-directiva-6b5616308"
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#32444E] bg-white rounded-full p-2 flex items-center justify-center w-10 h-10 transition duration-300 ease-in-out transform hover:bg-[#32444E] hover:text-white hover:scale-110"
              >
                <FontAwesomeIcon icon={faLinkedinIn} size="lg" />
              </a>
            </div>
          </div>

          {/* Footer navigation */}
          <nav className="w-full pt-6 md:w-1/2 lg:w-1/2 px-2">
            <div className="flex flex-wrap justify-between md:justify-center">
              <div className="w-1/2 md:w-1/3 lg:w-1/4 mb-4 md:mb-6 lg:mb-0 px-2 md:px-4">
                <h5 className="text-lg mb-3 font-bold text-white">HOME</h5>
                <ul className="list-none">
                  <li>
                    <Link
                      to="/"
                      className="text-white hover:text-[#d8ccb6] transition duration-300 ease-in-out"
                    >
                      Inicio
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/Membresias"
                      className="text-white hover:text-[#d8ccb6] transition duration-300 ease-in-out"
                    >
                      Membresías
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/Galeria"
                      className="text-white hover:text-[#d8ccb6] transition duration-300 ease-in-out"
                    >
                      Galería
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/Insights"
                      className="text-white hover:text-[#d8ccb6] transition duration-300 ease-in-out"
                    >
                      Insights
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="w-1/2 md:w-1/3 lg:w-1/4 mb-4 md:mb-6 lg:mb-0 px-2 md:px-4">
                <h5 className="text-lg mb-3 font-bold text-white">PLATFORM</h5>
                <ul className="list-none">
                  <li>
                    <p className="text-white hover:text-[#d8ccb6] transition duration-300 ease-in-out">
                      Link Up
                    </p>
                  </li>
                  <li>
                    <Link
                      to="/Contacto"
                      className="text-white hover:text-[#d8ccb6] transition duration-300 ease-in-out"
                    >
                      Contacto
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="w-1/2 md:w-1/3 lg:w-1/4 mb-4 md:mb-6 lg:mb-0 px-2 md:px-4">
                <h5 className="text-lg mb-3 font-bold text-white">SUPPORT</h5>
                <ul className="list-none">
                  <li>
                    <p className="text-white hover:text-[#d8ccb6] transition duration-300 ease-in-out">
                      Ayuda
                    </p>
                  </li>
                  <li>
                    <Link
                      to="/Contacto#faq"
                      className="text-white hover:text-[#d8ccb6] transition duration-300 ease-in-out"
                    >
                      Preguntas frecuentes
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>

          {/* Address and contact info */}
          <address className="w-full pt-6 md:w-1/2 lg:w-1/4 px-4 mb-4 md:mb-0 lg:text-left not-italic">
            <a
              href="https://www.google.com.mx/maps/place/Av.+San+Luis+Potos%C3%AD+63A,+San+Benito,+83190+Hermosillo,+Son./@29.0906359,-110.9597195,17z/data=!3m1!4b1!4m6!3m5!1s0x86ce8436af479131:0x20b9fcbb7456ac1f!8m2!3d29.0906312!4d-110.9571446!16s%2Fg%2F11c5kzk7fz?entry=tts"
              className="underline text-white hover:text-[#d8ccb6] transition duration-300 ease-in-out transform hover:scale-105"
              target="_blank"
              rel="noopener noreferrer"
            >
              Av. San Luis Potosí 63A, San Benito, 83190 Hermosillo, Son.
            </a>
            <p className="text-white">
              Tel.
              <a
                href="tel:+526622155689"
                className="text-white hover:text-[#d8ccb6] transition duration-300 ease-in-out transform hover:scale-105"
              >
                (662) 2-15-56-89
              </a>
            </p>
            <a
              href="mailto:contacto@cced.com.mx"
              className="text-white hover:text-[#d8ccb6] transition duration-300 ease-in-out transform hover:scale-105"
            >
              contacto@cced.mx
            </a>
          </address>

          {/* Scroll-to-top button */}
          <motion.button
            initial={{ opacity: 0, scale: 0 }}
            animate={{
              opacity: showScrollButton ? 1 : 0,
              scale: showScrollButton ? 1 : 0,
            }}
            transition={{ duration: 0.3 }}
            className="fixed bottom-4 right-2 p-3 mr-1 rounded-full bg-[#597276] hover:bg-[#4d6568] shadow-lg drop-shadow-xl flex items-center justify-center transition duration-300 ease-in-out z-50"
            onClick={scrollToTop}
          >
            <FontAwesomeIcon
              icon={faArrowUp}
              style={{
                color: "white",
                borderRadius: "50%",
                padding: "px",
              }}
              className="lg:text-3xl text-lg"
            />
          </motion.button>
        </div>

        {/* Bottom bar */}
        <div className="flex flex-col md:flex-row items-center justify-between text-sm mt-8 border-t border-gray-600 pt-4 md:px-16 lg:px-0">
          <div className="text-left">
            © Todos los derechos reservados
            <br />
            Consulta nuestro{" "}
            <Link
              to="/aviso-de-privacidad"
              className="hover:text-gray-400 transition duration-300 underline"
            >
              Aviso de privacidad
            </Link>
          </div>

          <div className="mt-4 md:mt-0 w-full md:w-auto">
            <h2 className="text-lg font-bold mb-2 text-center md:text-left">
              Insights
            </h2>
            <div
              className="flex flex-col items-center justify-center lg:flex-row lg:items-start lg:justify-start"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <form
                id="subscription-form"
                className="flex flex-col items-center justify-center lg:flex-row lg:items-start lg:justify-start"
                onSubmit={handleSubmit}
              >
                <AnimatePresence>
                  {(isHovered || !isLargeScreen) && (
                    <div className="flex flex-col items-center md:items-start md:flex-col lg:flex-row">
                      {isLargeScreen ? (
                        <>
                          <motion.div
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            transition={{ duration: 0.3, ease: "easeOut" }}
                          >
                            <label htmlFor="name" className="sr-only">
                              Nombre
                            </label>
                            <input
                              type="text"
                              id="name"
                              name="name"
                              placeholder="Nombre"
                              className="py-2 px-4 rounded-full border text-black border-gray-300 focus:outline-none focus:border-gray-400 mb-2 lg:mb-0 lg:mr-4"
                              onChange={handleInputChange}
                              value={formData.name}
                            />
                          </motion.div>
                          <motion.div
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            transition={{
                              duration: 0.3,
                              delay: 0.1,
                              ease: "easeOut",
                            }}
                          >
                            <label htmlFor="email" className="sr-only">
                              Correo electrónico
                            </label>
                            <input
                              type="email"
                              id="email"
                              name="email"
                              placeholder="Correo electrónico"
                              className="py-2 px-4 rounded-full text-black border border-gray-300 focus:outline-none focus:border-gray-400 mb-2 lg:mb-0"
                              onChange={handleInputChange}
                              value={formData.email}
                            />
                          </motion.div>
                          <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: 20 }}
                            transition={{
                              duration: 0.3,
                              delay: 0.2,
                              ease: "easeOut",
                            }}
                          >
                            <div className="pt-4 lg:pt-0 lg:pl-4">
                              <label
                                htmlFor="privacy"
                                className="flex items-center"
                              >
                                <input
                                  type="checkbox"
                                  id="privacy"
                                  name="privacy"
                                  className="mr-2"
                                  onChange={handleCheckboxChange}
                                />
                                {`He leído el\u00A0`}
                                <Link
                                  to="/aviso-de-privacidad"
                                  className="hover:text-gray-400 transition duration-300"
                                >
                                  aviso de privacidad
                                </Link>
                              </label>
                            </div>
                          </motion.div>
                        </>
                      ) : (
                        <>
                          <div className="mb-2 w-full">
                            <label htmlFor="name" className="sr-only">
                              Nombre
                            </label>
                            <input
                              type="text"
                              id="name"
                              name="name"
                              placeholder="Nombre"
                              className="py-2 px-4 md:px-6 rounded-full border text-black border-gray-300 focus:outline-none focus:border-gray-400 w-full"
                              onChange={handleInputChange}
                              value={formData.name}
                            />
                          </div>
                          <div className="mb-2 w-full">
                            <label htmlFor="email" className="sr-only">
                              Correo electrónico
                            </label>
                            <input
                              type="email"
                              id="email"
                              name="email"
                              placeholder="Correo electrónico"
                              className="py-2 px-4 md:px-6 rounded-full text-black border border-gray-300 focus:outline-none focus:border-gray-400 w-full"
                              onChange={handleInputChange}
                              value={formData.email}
                            />
                          </div>
                          <div className="w-full">
                            <div className="pt-4 md:pt-0">
                              <label
                                htmlFor="privacy"
                                className="flex items-center"
                              >
                                <input
                                  type="checkbox"
                                  id="privacy"
                                  name="privacy"
                                  className="mr-2"
                                  onChange={handleCheckboxChange}
                                />
                                {`He leído el\u00A0`}
                                <Link
                                  to="/aviso-de-privacidad"
                                  className="hover:text-gray-400 transition duration-300"
                                >
                                  aviso de privacidad
                                </Link>
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </AnimatePresence>
                <motion.button
                  id="subscribe-button"
                  initial={{ x: 0 }}
                  animate={{ x: isHovered && isLargeScreen ? 20 : 0 }}
                  exit={{ x: 0 }}
                  transition={{ type: "spring", stiffness: 100, damping: 10 }}
                  type="submit"
                  className=" bg-[#597276] z-50 text-white font-bold py-2 px-4 md:px-6 rounded-full hover:bg-[#3e5152] hover:text-white transition duration-300 ease-in-out mt-4 md:mt-4 lg:mt-0"
                >
                  Suscribirse
                </motion.button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
