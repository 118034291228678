import React, { useEffect, useReducer } from "react";
import ArticleCard from "../components/ArticleCard";
import { Article } from "../utils/interfaces";
import { initialState, reducer } from "../utils/state";
import { motion, AnimatePresence } from "framer-motion";
import { useInView } from "react-intersection-observer";

/**
 * Insights component that displays a collection of blog articles with pagination and search functionality.
 */
const Insights: React.FC = () => {
  const scrollToSuscripcion = () => {
    const suscripcionSection = document.getElementById("suscripcion");

    if (suscripcionSection) {
      suscripcionSection.scrollIntoView({ behavior: "smooth" });

      const suscribirseBtn = document.getElementById("suscribirsebtn");
      if (suscribirseBtn) {
        suscribirseBtn.style.backgroundColor = "yellow";

        const hoverEvent = new MouseEvent("mouseover", {
          bubbles: true,
          cancelable: true,
          view: window,
        });

        suscribirseBtn.dispatchEvent(hoverEvent);

        setTimeout(() => {
          suscribirseBtn.style.backgroundColor = "";
        }, 2000);
      }
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const [recentArticlesRef, recentArticlesInView] = useInView({
    triggerOnce: true,
  });
  const [allArticlesRef, allArticlesInView] = useInView({ triggerOnce: true });

  useEffect(() => {
    const fetchArticles = async (): Promise<Article[]> => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/articulos?populate[imagen]=url&populate[autor][populate]=nombre,imagen&populate[tags]=nombre`
        );
        if (!response.ok) throw new Error("Network response was not ok");
        const json = await response.json();
        return json.data
          .map((item: any) => ({
            id: item.id,
            title: item.attributes.titulo,
            content: item.attributes.contenido,
            publishedAt: item.attributes.publishedAt,
            image: item.attributes.imagen?.data?.[0]?.attributes,
            author: {
              name: item.attributes.autor.data.attributes.nombre,
              image:
                item.attributes.autor.data.attributes.imagen?.data?.[0]
                  ?.attributes,
            },
            tags: item.attributes.tags.data.map((tag: any) => ({
              name: tag.attributes.nombre,
            })),
          }))
          .sort((a: Article, b: Article) =>
            b.publishedAt.localeCompare(a.publishedAt)
          );
      } catch (error) {
        console.error("Fetching error:", error);
        return [];
      }
    };

    fetchArticles().then((articles) => {
      dispatch({ type: "SET_ARTICLES", payload: articles });
      dispatch({ type: "SET_RECENT_ARTICLES", payload: articles });
      const allTags = articles.reduce<string[]>((tags, article) => {
        article.tags.forEach((tag) => {
          if (!tags.includes(tag.name)) {
            tags.push(tag.name);
          }
        });
        return tags;
      }, []);
      dispatch({ type: "SET_ALL_TAGS", payload: allTags });
    });
  }, []);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value;
    dispatch({ type: "SET_SEARCH_TERM", payload: searchTerm });

    const noResults =
      state.articles.filter((article) =>
        article.title.toLowerCase().includes(searchTerm.toLowerCase())
      ).length === 0;

    dispatch({ type: "SET_NO_RESULTS", payload: noResults });
  };

  const itemsPerPage = 6;

  const variants = {
    enter: (direction: number) => ({
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
      transition: {
        x: { type: "spring", stiffness: 300, damping: 30 },
        opacity: { duration: 0.5 },
      },
    }),
    center: {
      x: 0,
      opacity: 1,
      transition: {
        x: { type: "spring", stiffness: 300, damping: 30 },
        opacity: { duration: 0.5 },
      },
    },
    exit: (direction: number) => ({
      x: -Math.sign(direction) * 1000,
      opacity: 0,
      transition: {
        x: { type: "spring", stiffness: 300, damping: 30 },
        opacity: { duration: 0.5 },
      },
    }),
  };

  return (
    <div className="relative -top-28">
      <div className="relative w-full flex flex-col h-dvh">
        {/* Background Image Container */}
        <div
          id="insights-background-section"
          className="relative w-full h-full"
        >
          <picture className="absolute inset-0 w-full h-full object-cover">
            <source
              srcSet="
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716600829/Insights_bg_f83fcf7fa5.webp 480w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716600829/Insights_bg_f83fcf7fa5.webp 800w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716600829/Insights_bg_f83fcf7fa5.webp 1200w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1716600829/Insights_bg_f83fcf7fa5.webp 1600w"
              sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
              type="image/webp"
            />
            <source
              srcSet="
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716600829/Insights_bg_f83fcf7fa5.png 480w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716600829/Insights_bg_f83fcf7fa5.png 800w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716600829/Insights_bg_f83fcf7fa5.png 1200w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/w_1600/v1716600829/Insights_bg_f83fcf7fa5.png 1600w"
              sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
              type="image/png"
            />
            <img
              src="https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/v1716600829/Insights_bg_f83fcf7fa5.png"
              alt="Background"
              className="absolute inset-0 w-full h-full object-cover"
              loading="lazy"
              id="insights-background-image"
            />
          </picture>

          <div className="absolute inset-0 bg-gradient-to-b from-neutral-800 via-transparent"></div>
        </div>

        {/* Overlay */}
        <div className="hidden md:flex absolute inset-0 z-10">
          <div className="w-3/6 h-full bg-[#5A6D86] opacity-50"></div>
          <div className="w-3/4 h-full bg-transparent flex flex-col justify-center items-end ">
            <div
              className="text-xl md:text-2xl  text-white bg-[rgba(50,68,78,.8)] p-6 md:p-12 text-right shadow-lg flex flex-col"
              style={{ textShadow: "1px 1px 4px rgba(0, 0, 0, 0.5)" }}
            >
              <span className="mb-6 py-16 pl-32">
                Te ofrecemos conocimientos frescos y perspectivas innovadoras
                para enfrentar los desafíos empresariales de hoy. Descubre ideas
                poderosas y estrategias efectivas para tu éxito.
              </span>
              <span className="mt-auto">
                <button
                  className="text-lg md:text-xl font-semibold bg-[#B9C5D5] text-[#2B2227] py-2 px-4 rounded-full shadow-lg transition duration-150 ease-in-out"
                  onClick={scrollToSuscripcion}
                >
                  Suscribirse
                </button>
              </span>
            </div>
          </div>
        </div>
        {/* Bottom Aligned Text Content for Mobile */}
        <div className="absolute inset-0 flex-grow pb-16 px-4 flex flex-col justify-end text-white z-10 md:hidden">
          <p
            className="text-xl md:text-3xl font-bold text-[#B9C5D5] bg-[rgba(50,68,78,.8)] p-6 md:p-12 text-right shadow-lg flex flex-col"
            style={{ textShadow: "1px 1px 4px rgba(0, 0, 0, 0.5)" }}
          >
            <span className="mb-6">
              Te ofrecemos conocimientos frescos y perspectivas innovadoras para
              enfrentar los desafíos empresariales de hoy. Descubre ideas
              poderosas y estrategias efectivas para tu éxito.
            </span>
            <span className="mt-auto">
              <button
                className="text-lg md:text-xl font-semibold bg-[#B9C5D5] text-[#2B2227] py-2 px-4 rounded-full shadow-lg transition duration-150 ease-in-out"
                onClick={scrollToSuscripcion}
              >
                Suscribirse
              </button>
            </span>
          </p>
        </div>
      </div>

      <div className="container mx-auto py-24">
        <section aria-labelledby="recent-articles">
          <motion.h2
            ref={recentArticlesRef}
            initial={{ opacity: 0, y: 50 }}
            animate={recentArticlesInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.8, ease: "easeOut" }}
            id="recent-articles"
            className="text-3xl font-bold mb-8 mx-4 text-white"
          >
            Artículos recientes del blog
          </motion.h2>
          <motion.div
            ref={recentArticlesRef}
            initial={{ opacity: 0, y: 50 }}
            animate={recentArticlesInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.8, ease: "easeOut" }}
            className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 pb-12 px-4"
          >
            {state.recentArticles.slice(0, 3).map((article: Article) => (
              <ArticleCard article={article} key={article.id} />
            ))}
          </motion.div>
        </section>

        <section aria-labelledby="all-articles">
          <motion.h2
            ref={allArticlesRef}
            initial={{ opacity: 0, y: 50 }}
            animate={allArticlesInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.8, ease: "easeOut" }}
            id="all-articles"
            className="text-3xl font-bold mb-8 text-white mx-4"
          >
            Todas las publicaciones del blog
          </motion.h2>
          <div className="flex flex-col justify-end items-end space-y-4 mx-4">
            <label htmlFor="search" className="sr-only">
              Buscar artículo por nombre
            </label>
            <input
              type="text"
              id="search"
              placeholder="Buscar artículo por nombre"
              value={state.searchTerm}
              onChange={handleSearchChange}
              className="w-1/3 px-4 py-2 mb-4 rounded-full bg-[#B9C5D5] bg-opacity-20 border-[#B9C5D5] focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="items-center justify-center">
            {state.noResults ? (
              <div className="text-red-600 text-center text-2xl font-bold p-4">
                No se encontraron artículos
              </div>
            ) : (
              <div>{/* Aquí renderiza tus artículos */}</div>
            )}
          </div>

          <AnimatePresence initial={false} custom={state.direction}>
            <motion.div
              className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 px-4"
              key={state.currentPage}
              custom={state.direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
            >
              {(state.searchTerm !== ""
                ? state.articles.filter((article) =>
                    article.title
                      .toLowerCase()
                      .includes(state.searchTerm.toLowerCase())
                  )
                : state.articles.slice(
                    (state.currentPage - 1) * itemsPerPage,
                    state.currentPage * itemsPerPage
                  )
              ).map((article: Article) => (
                <ArticleCard article={article} key={article.id} />
              ))}
            </motion.div>
          </AnimatePresence>

          <div className="mt-4 pt-4 border-t border-white">
            <div className="flex justify-between items-center">
              <NavigationButton
                direction="previous"
                currentPage={state.currentPage}
                pageCount={Math.ceil(state.articles.length / itemsPerPage)}
                paginate={(pageNumber: number) =>
                  dispatch({ type: "SET_CURRENT_PAGE", payload: pageNumber })
                }
              />
              <div className="flex space-x-2">
                {Array.from(
                  { length: Math.ceil(state.articles.length / itemsPerPage) },
                  (_, i) => i + 1
                ).map((number) => (
                  <button
                    key={number}
                    onClick={() =>
                      dispatch({ type: "SET_CURRENT_PAGE", payload: number })
                    }
                    className={`px-4 py-2 rounded-md transition duration-300 ease-in-out transform hover:scale-110 ${
                      state.currentPage === number
                        ? "bg-gray-300 text-black"
                        : "bg-[rgba(185,197,213,0.2)] text-white hover:bg-gray-300"
                    }`}
                  >
                    {number}
                  </button>
                ))}
              </div>
              <NavigationButton
                direction="next"
                currentPage={state.currentPage}
                pageCount={Math.ceil(state.articles.length / itemsPerPage)}
                paginate={(pageNumber: number) =>
                  dispatch({ type: "SET_CURRENT_PAGE", payload: pageNumber })
                }
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

/**
 * NavigationButton component for pagination controls.
 * @param {Object} props - The component props.
 * @param {string} props.direction - The direction of the navigation ("previous" or "next").
 * @param {number} props.currentPage - The current page number.
 * @param {number} props.pageCount - The total number of pages.
 * @param {function} props.paginate - The function to call when navigating to a different page.
 * @returns {JSX.Element} The NavigationButton component.
 */
function NavigationButton({
  direction,
  currentPage,
  pageCount,
  paginate,
}: {
  direction: string;
  currentPage: number;
  pageCount: number;
  paginate: (pageNumber: number) => void;
}) {
  const isPrevious = direction === "previous";
  const isEnabled = isPrevious ? currentPage > 1 : currentPage < pageCount;

  return (
    <button
      onClick={() => paginate(isPrevious ? currentPage - 1 : currentPage + 1)}
      className={`px-4 py-2 text-white transition duration-300 ease-in-out transform ${
        isEnabled
          ? "cursor-pointer hover:scale-110"
          : "cursor-not-allowed opacity-50"
      }`}
      disabled={!isEnabled}
    >
      {isPrevious ? "< Anterior" : "Siguiente >"}
    </button>
  );
}

export default Insights;
