import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const usePageTitle = (defaultTitle: string) => {
  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;
    let title = defaultTitle;

    switch (pathname) {
      case '/':
        title = 'Centro de Conexión Estratégica y Directiva (CCED)';
        break;
      case '/nosotros':
        title = 'Nosotros - CCED';
        break;
      case '/membresias':
        title = 'Membresías - CCED';
        break;
      case '/galeria':
        title = 'Galería - CCED';
        break;
      case '/contacto':
        title = 'Contacto - CCED';
        break;
      case '/insights':
        title = 'Insights - CCED';
        break;
      default:
        title = 'CCED';
        break;
    }

    document.title = title;
  }, [location, defaultTitle]);
};

export default usePageTitle;
