import React, { useState, useEffect, useCallback } from "react";
import Swal from "sweetalert2";
import CommentLikeButton from "../components/CommentLikeButton";

interface Comment {
  id: number;
  content: string;
  likes: number;
  replies: Comment[];
  parent: number | null;
  autor_comentario: string;
}

interface CommentSectionProps {
  articleId: string;
}

const forbiddenWords = [
  "idiota",
  "estúpido",
  "tonto",
  "imbécil",
  "mierda",
  "puta",
  "puto",
  "joder",
  "maldito",
  "cabron",
  "gilipollas",
  "coño",
  "cabrón",
  "maricón",
  "zorra",
  "pendejo",
  "cagada",
  "perra",
  "putazo",
  "chinga",
  "culero",
  "verga",
  "chingada",
  "pendeja",
  "culera",
  "follar",
  "polla",
  "carajo",
  "cojones",
  "pinche",
  "pajero",
  "pajera",
  "putona",
  "culazo",
  "chupapollas",
  "mamón",
  "mamona",
  "chingadera",
  "cabronazo",
  "mierdero",
  "mamahuevo",
  "mamaguevo",
  "mamapinga",
  "comemierda",
  "lameculos",
  "chupaculos",
  "chupapijas",
  "huevón",
  "pendejada",
  "pinche",
  "chingaquedito",
  "culero",
  "chingao",
  "chingón",
  "chingón",
  "hijueputa",
  "vergon",
  "meapilas",
  "perroflauta",
  "muerdealmohadas",
  "tontainas",
  "comemierdas",
  "pendejazo",
  "mamadas",
  "pincheputo",
  "chingatumadre",
  "putamadre",
  "perras",
  "cojudo",
  "concha",
  "pinchecabron",
  "chupamela",
  "vetealaverga",
  "cagón",
  "chingao",
  "vergon",
  "chingatumadre",
  "putamadre",
  "perras",
  "cojudo",
  "pinchecabron",
  "chupamela",
  "vetealaverga",
  "putamadre",
  "perras",
  "concha",
  "cagón",
  "cagona",
  "pendejada",
  "pinche",
  "chingaquedito",
  "mierdero",
  "culón",
  "cabronazo",
  "pendejazo",
  "pendejez",
  "puñetas",
  "puñetero",
  "puñetera",
  "pajillero",
  "pajera",
  "chingadera",
  "chingado",
  "cabrón",
  "chingón",
  "culero",
  "chingado",
  "chingao",
  "chingoncísimo",
  "huevón",
  "mamón",
  "cabrona",
  "mamada",
  "pendejazo",
  "cabronazo",
  "chingadazo",
  "pendejada",
  "chingadera",
  "chingón",
  "pendeja",
  "culero",
  "culera",
  "cabrona",
  "pendejo",
  "pendeja",
  "chinga",
  "chingas",
  "chingar",
  "chingue",
  "chingó",
  "chingamos",
  "chingaron",
  "chingarás",
  "chingaría",
  "chingaría",
  "chingarán",
  "chingaron",
  "chingaste",
  "chingara",
  "chingas",
  "chingas",
  "chingada",
  "chingaderas",
  "pendejo",
  "pendeja",
  "pendejos",
  "pendejas",
  "pendejismo",
  "pendejez",
  "pendejísimo",
  "pendejo",
  "chingón",
  "chingona",
  "chingones",
  "chingonas",
  "chingón",
  "chingona",
  "chingones",
  "chingonas",
  "chingoncísimo",
  "chingoncísima",
  "chingoncísimos",
  "chingoncísimas",
  "chingados",
  "chingadas",
  "chingaderas",
  "chingadera",
  "chingaquedito",
  "chingaderas",
  "chingadazo",
  "chingadazos",
  "chingaditas",
  "chingar",
  "chingó",
  "chingaron",
  "chingan",
  "chingamos",
  "chingarán",
  "chingarás",
  "chingaría",
  "chingarías",
  "chingaríamos",
  "chingarían",
  "chingaste",
  "chingasteis",
  "chingarías",
  "chingaríamos",
  "chingarían",
];

const CommentSection: React.FC<CommentSectionProps> = ({ articleId }) => {
  const [comments, setComments] = useState<Comment[]>([]);
  const [newComment, setNewComment] = useState<string>("");
  const [newAuthor, setNewAuthor] = useState<string>(
    localStorage.getItem("commentAuthor") || ""
  );
  const [authorSaved, setAuthorSaved] = useState<boolean>(
    !!localStorage.getItem("commentAuthor")
  );

  const fetchComments = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/articulos/${articleId}?populate[comments][populate]=content,likes,parent,autor_comentario`
      );

      if (!response.ok) {
        throw new Error(`Error fetching comments: ${response.statusText}`);
      }

      const json = await response.json();
      if (json.data && json.data.attributes && json.data.attributes.comments) {
        const fetchedComments: Comment[] =
          json.data.attributes.comments.data.map((comment: any) => ({
            id: comment.id,
            content: comment.attributes.content,
            likes: comment.attributes.likes,
            replies: [],
            parent: comment.attributes.parent?.data?.id || null,
            autor_comentario: comment.attributes.autor_comentario || "Anónimo",
          }));
        setComments(fetchedComments);
      } else {
        console.error("Invalid response structure: Missing comments data");
      }
    } catch (error: any) {
      console.error("Error fetching comments:", error.message);
    }
  }, [articleId]);

  useEffect(() => {
    fetchComments();
  }, [fetchComments]);

  const containsForbiddenWords = (text: string) => {
    const lowerCaseText = text.toLowerCase();
    return forbiddenWords.some((word) => lowerCaseText.includes(word));
  };

  const handleAddComment = async () => {
    if (!newComment.trim() || (!newAuthor.trim() && !authorSaved)) return;

    if (
      containsForbiddenWords(newComment) ||
      containsForbiddenWords(newAuthor)
    ) {
      Swal.fire({
        icon: "error",
        title: "Comentario inapropiado",
        text: "Tu comentario o nombre contiene palabras inapropiadas. Por favor, edítalo.",
      });
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/comments`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: {
              content: newComment,
              autor_comentario:
                newAuthor || localStorage.getItem("commentAuthor"),
              article: articleId,
            },
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Error al añadir el comentario");
      }

      setNewComment("");
      if (!authorSaved) {
        localStorage.setItem("commentAuthor", newAuthor);
        setAuthorSaved(true);
        Swal.fire({
          icon: "success",
          title: "Nombre guardado con éxito",
          text: "Tu nombre se ha guardado correctamente y será utilizado para futuros comentarios.",
        });
      } else {
        Swal.close();
      }
      await fetchComments(); // Refetch comments to update the list
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Hubo un problema al añadir tu comentario. Inténtalo de nuevo.",
      });
      console.error("Error adding comment:", error);
    }
  };

  const handleAddReply = async (parentId: number, replyContent: string) => {
    if (!replyContent.trim()) return;

    if (
      containsForbiddenWords(replyContent) ||
      containsForbiddenWords(newAuthor)
    ) {
      Swal.fire({
        icon: "error",
        title: "Comentario inapropiado",
        text: "Tu respuesta o nombre contiene palabras inapropiadas. Por favor, edítalo.",
      });
      return;
    }

    if (!newAuthor.trim() && !authorSaved) {
      Swal.fire({
        title: "¡Hola!",
        text: "Para poder responder, por favor introduce tu nombre. Esto nos ayudará a mantener una conversación más personal.",
        input: "text",
        inputPlaceholder: "Tu nombre",
        showCancelButton: true,
        confirmButtonText: "Guardar",
        cancelButtonText: "Cancelar",
        preConfirm: (name) => {
          if (!name) {
            Swal.showValidationMessage("El nombre es requerido");
          } else {
            setNewAuthor(name);
            localStorage.setItem("commentAuthor", name);
            setAuthorSaved(true);
            return name;
          }
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (containsForbiddenWords(result.value)) {
            Swal.fire({
              icon: "error",
              title: "Nombre inapropiado",
              text: "Tu nombre contiene palabras inapropiadas. Por favor, edítalo.",
            });
            return;
          }

          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/comments`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                data: {
                  content: replyContent,
                  autor_comentario: result.value,
                  parent: parentId,
                  article: articleId,
                },
              }),
            }
          );

          if (!response.ok) {
            throw new Error("Error al añadir la respuesta");
          }

          Swal.fire({
            icon: "success",
            title: "Nombre guardado con éxito",
            text: "Tu nombre se ha guardado correctamente y será utilizado para futuros comentarios.",
          });

          await fetchComments(); // Refetch comments to update the list
        }
      });
    } else {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/comments`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              data: {
                content: replyContent,
                autor_comentario:
                  newAuthor || localStorage.getItem("commentAuthor"),
                parent: parentId,
                article: articleId,
              },
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Error al añadir la respuesta");
        }

        Swal.close();
        await fetchComments(); // Refetch comments to update the list
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Hubo un problema al añadir tu respuesta. Inténtalo de nuevo.",
        });
        console.error("Error adding reply:", error);
      }
    }
  };

  const nestReplies = (comments: Comment[]) => {
    const commentMap: { [key: number]: Comment } = {};
    comments.forEach((comment) => {
      comment.replies = [];
      commentMap[comment.id] = comment;
    });

    const nestedComments: Comment[] = [];
    comments.forEach((comment) => {
      if (comment.parent) {
        commentMap[comment.parent].replies.push(comment);
      } else {
        nestedComments.push(comment);
      }
    });

    return nestedComments;
  };

  const renderReplies = (replies: Comment[]) => {
    return replies.map((reply) => (
      <div key={reply.id} className="ml-4 border-l pl-4 mt-4">
        <p className="text-[#D8CCB6]">{reply.content}</p>
        <p className="text-sm text-gray-400 mb-2">
          Por: {reply.autor_comentario}
        </p>
        <CommentLikeButton commentId={reply.id} initialLikes={reply.likes} />
        {reply.replies.length > 0 && renderReplies(reply.replies)}
        <div className="add-reply mt-4">
          <textarea
            placeholder="Responder..."
            className="w-full p-2 rounded-md bg-[#2B2227] text-white border border-[#5A6D86] focus:ring-2 focus:ring-[#5A6D86]"
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handleAddReply(reply.id, e.currentTarget.value);
                e.currentTarget.value = "";
              }
            }}
          ></textarea>
        </div>
      </div>
    ));
  };

  const renderComments = (comments: Comment[]) => {
    const nestedComments = nestReplies(comments);
    return nestedComments.map((comment) => (
      <div
        key={comment.id}
        className="comment bg-[#32444E] p-4 mb-4 rounded-md"
      >
        <p className="text-white mb-2">{comment.content}</p>
        <p className="text-sm text-gray-400 mb-2">
          Por: {comment.autor_comentario}
        </p>
        <CommentLikeButton
          commentId={comment.id}
          initialLikes={comment.likes}
        />
        <div className="replies mt-4 pl-4 border-l-2 border-[#597276]">
          {comment.replies.length > 0 && renderReplies(comment.replies)}
          <div className="add-reply mt-4">
            <textarea
              placeholder="Responder..."
              className="w-full p-2 rounded-md bg-[#2B2227] text-white border border-[#5A6D86] focus:ring-2 focus:ring-[#5A6D86]"
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  handleAddReply(comment.id, e.currentTarget.value);
                  e.currentTarget.value = "";
                }
              }}
            ></textarea>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div className="comment-section mt-8 mx-4 md:mx-10 lg:mx-20">
      <h3 className="text-2xl font-semibold text-[#D8CCB6] mb-4">
        Comentarios
      </h3>
      <div className="add-comment mb-6">
        {!authorSaved && (
          <input
            type="text"
            value={newAuthor}
            onChange={(e) => setNewAuthor(e.target.value)}
            placeholder="Tu nombre"
            className="w-full p-2 mb-2 rounded-md bg-[#2B2227] text-white border border-[#5A6D86] focus:ring-2 focus:ring-[#5A6D86]"
          />
        )}
        <textarea
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          placeholder="Añadir un comentario..."
          className="w-full p-4 rounded-md bg-[#2B2227] text-white border border-[#5A6D86] focus:ring-2 focus:ring-[#5A6D86]"
        ></textarea>
        <button
          onClick={handleAddComment}
          className="mt-2 px-4 py-2 bg-[#5A6D86] text-white rounded-md hover:bg-[#9BC5D5] transition duration-150 ease-in-out"
        >
          Comentar
        </button>
      </div>
      {renderComments(comments)}
    </div>
  );
};

export default CommentSection;
