import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

const pageVariants = {
  initial: { opacity: 0, y: 20 },
  in: { 
    opacity: 1, 
    y: 0, 
    transition: { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] } 
  },
  out: { 
    opacity: 0, 
    y: -20, 
    transition: { duration: 0.3, ease: [0.43, 0.13, 0.23, 0.96] } 
  },
};

const withPageTransition = <P extends object>(Component: React.ComponentType<P>): React.FC<P> => {
  return function WrappedComponent(props: P) {
    const location = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
      <motion.div
        variants={pageVariants}
        initial="initial"
        animate="in"
        exit="out"
      >
        <Component {...props} />
      </motion.div>
    );
  };
};

export default withPageTransition;
