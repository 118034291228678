import React from "react";
import { motion } from "framer-motion";

/**
 * LinkUpComingSoon component that displays a "Coming Soon" message
 * with an animated loader and background effects.
 */
const LinkUpComingSoon: React.FC = () => {
  return (
    <div
      id="coming-soon-container"
      className="flex flex-col items-center justify-center min-h-screen bg-[#2b2227] text-white p-4 md:p-8 lg:p-16"
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
        className="flex flex-col items-center p-8 bg-[#32444e] rounded-lg shadow-lg relative overflow-hidden max-w-md w-full mx-4"
      >
        <div className="relative mb-6 w-full flex justify-center">
          <img
            src="/static/logov2.png"
            alt="LinkUp Logo"
            className="w-1/2 h-auto relative z-10"
            id="coming-soon-logo"
          />
          <div className="absolute inset-0 w-full h-full rounded-full shadow-inner"></div>
        </div>
        <h1 id="coming-soon-title" className="text-4xl font-bold mb-4">
          LinkUp
        </h1>
        <p id="coming-soon-subtitle" className="text-xl">
          Próximamente
        </p>
        <div id="coming-soon-loader" className="mt-6 flex justify-center items-center">
          <div className="relative">
            <div className="w-12 h-12 border-4 border-t-transparent border-white rounded-full animate-spin"></div>
            <div className="absolute top-0 left-0 w-12 h-12 border-4 border-t-transparent border-[#d8ccb6] rounded-full animate-spin delay-150"></div>
          </div>
        </div>
        <p id="coming-soon-message" className="mt-4 text-center">
          Estamos trabajando arduamente para ofrecerte una experiencia
          increíble. ¡Mantente atento!
        </p>
        <motion.div
          className="absolute inset-0 border-4 border-[#d8ccb6] rounded-lg"
          initial={{ opacity: 0 }}
          animate={{
            opacity: [0, 1, 0],
            borderWidth: ["4px", "8px", "4px"],
            borderColor: ["#d8ccb6", "#ffffff", "#d8ccb6"],
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            ease: "easeInOut",
          }}
        ></motion.div>
      </motion.div>
    </div>
  );
};

export default LinkUpComingSoon;
