import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga4';
import CookieConsent from 'react-cookie-consent';

// Initialize Google Analytics 4 with your measurement ID
ReactGA.initialize('G-VVND37W201');


// Function to send web vitals to Google Analytics
const sendToAnalytics = ({ id, name, value }: { id: any, name: any, value: any }) => {
  const roundedValue = Math.round(name === 'CLS' ? value * 1000 : value);
  ReactGA.event({
    category: 'Web Vitals',
    action: name,
    label: id,
    value: roundedValue,
    nonInteraction: true, // Prevents the event from affecting bounce rate
  });
};

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');

const root = ReactDOM.createRoot(rootElement);

root.render(
  <React.StrictMode>
    <App />
    <CookieConsent
      location="bottom"
      buttonText="Aceptar"
      cookieName="ccedCookies"
      containerClasses="bg-dark-primary text-light-primary p-4"
      buttonClasses="bg-accent hover:bg-dark-accent text-dark-primary font-semibold py-2 px-4 rounded"
      expires={150}
      contentClasses="inline"
      buttonWrapperClasses="inline"
    >
      Este sitio web utiliza cookies para brindarle la mejor experiencia. Al continuar navegando, acepta el uso de cookies.{" "}
      <a href="/politica-de-privacidad" className="text-accent underline hover:text-dark-accent">Más información</a>
    </CookieConsent>
  </React.StrictMode>
);

// Ensure that reportWebVitals is called after the root is rendered
reportWebVitals(sendToAnalytics);
