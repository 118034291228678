import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import "animate.css";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

// Set root element for Modal
Modal.setAppElement("#root");

/**
 * Image interface representing the structure of image data fetched from the API.
 */
interface Image {
  id: number;
  attributes: {
    Titulo: string;
    descripcion: string;
    cover: {
      data: {
        attributes: {
          url: string;
        };
      };
    };
  };
}

/**
 * Fetch images from the API.
 * @returns {Promise<Image[]>} A promise that resolves to an array of images.
 */
const fetchImages = async (): Promise<Image[]> => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/imagenes?populate=*&select=Titulo,cover.url`
    );
    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }
    const data = await response.json();
    return data.data || [];
  } catch (error) {
    throw new Error(
      `Fetching error: ${
        error instanceof Error ? error.message : "Unknown error"
      }`
    );
  }
};

/**
 * Get the full URL of an image.
 * @param {string} url - The relative URL of the image.
 * @returns {string} The full URL of the image.
 */
const getImageUrl = (url: string): string => {
  if (url.startsWith("http")) {
    return url;
  }
  return `${process.env.REACT_APP_API_URL}${url}`;
};

/**
 * ImageTile component for displaying individual image tiles.
 * @param {Object} props - The component props.
 * @param {Image} props.image - The image data.
 * @param {function} props.onSelect - The function to call when the image is selected.
 * @returns {JSX.Element} The ImageTile component.
 */
const ImageTile: React.FC<{
  image: Image;
  onSelect: (image: Image) => void;
  className?: string;
}> = ({ image, onSelect, className }) => (
  <div className={`relative max-w-xs my-4 ${className}`}>
    <div
      className="relative inline-block cursor-pointer"
      onClick={() => onSelect(image)}
      key={image.id}
    >
      <picture className="object-cover w-full h-full transition-transform duration-300 ease-in-out hover:scale-105">
        <source
          srcSet={`
            ${getImageUrl(image.attributes.cover.data.attributes.url)}?format=webp&w=480 480w,
            ${getImageUrl(image.attributes.cover.data.attributes.url)}?format=webp&w=800 800w,
            ${getImageUrl(image.attributes.cover.data.attributes.url)}?format=webp&w=1200 1200w,
            ${getImageUrl(image.attributes.cover.data.attributes.url)}?format=webp&w=1600 1600w
          `}
          sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
          type="image/webp"
        />
        <source
          srcSet={`
            ${getImageUrl(image.attributes.cover.data.attributes.url)}?w=480 480w,
            ${getImageUrl(image.attributes.cover.data.attributes.url)}?w=800 800w,
            ${getImageUrl(image.attributes.cover.data.attributes.url)}?w=1200 1200w,
            ${getImageUrl(image.attributes.cover.data.attributes.url)}?w=1600 1600w
          `}
          sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
          type="image/jpeg"
        />
        <img
          src={getImageUrl(image.attributes.cover.data.attributes.url)}
          alt={image.attributes.Titulo}
          className="object-cover w-full h-full"
          loading="lazy"
        />
      </picture>
      <div className="absolute inset-0 flex items-center justify-center bg-[#32444E] bg-opacity-50 opacity-0 transition-opacity duration-500 ease-in-out hover:opacity-100">
        <p className="text-white text-xl font-bold">
          {image.attributes.Titulo}
        </p>
      </div>
    </div>
  </div>
);


/**
 * Gallery component for displaying a gallery of images.
 * @returns {JSX.Element} The Gallery component.
 */
const Gallery: React.FC = () => {
  const [images, setImages] = useState<Image[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [selectedImage, setSelectedImage] = useState<Image | null>(null);

  const [galleryRef, galleryInView] = useInView({ triggerOnce: true });
  const [image1Ref, image1InView] = useInView({ triggerOnce: true });
  const [image2Ref, image2InView] = useInView({ triggerOnce: true });

  useEffect(() => {
    fetchImages()
      .then((fetchedImages) => {
        setImages(fetchedImages); // Set images without shuffling
      })
      .catch((error) => {
        setError(error.message);
      });
  }, []);

  if (error) return <div>Error loading images: {error}</div>;

  return (
    <>
      <div className="relative -top-36">
        <div
          id="gallery-section"
          className="relative w-full flex flex-col h-screen md:h-dvh"
        >
          {/* Background Image Container */}
          <picture className="absolute inset-0 w-full h-full object-cover">
            <source
              srcSet="
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716597516/gallery_bg_c2897db9a8.webp 480w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716597516/gallery_bg_c2897db9a8.webp 800w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716597516/gallery_bg_c2897db9a8.webp 1200w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/w_1600/v1716597516/gallery_bg_c2897db9a8.webp 1600w"
              sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
              type="image/webp"
            />
            <source
              srcSet="
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716597516/gallery_bg_c2897db9a8.png 480w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/w_800/v1716597516/gallery_bg_c2897db9a8.png 800w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto/q_auto/w_1200/v1716597516/gallery_bg_c2897db9a8.png 1200w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto/q_auto/w_1600/v1716597516/gallery_bg_c2897db9a8.png 1600w"
              sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
              type="image/png"
            />
            <img
              src="https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/v1716597516/gallery_bg_c2897db9a8.png"
              alt="Background"
              className="absolute inset-0 w-full h-full object-cover"
              loading="lazy"
              id="gallery-background-image"
            />
          </picture>

          <div className="absolute inset-0 bg-gradient-to-b from-neutral-800 via-transparent"></div>

          {/* Bottom Aligned Text Content */}
          <div
            id="gallery-text-content"
            className="flex-grow pb-12 md:pb-32 px-8 md:px-40 flex items-end justify-center text-white z-10"
          >
            <p
              id="gallery-text"
              className="text-xl md:text-3xl font-bold text-[#B9C5D5] bg-[rgba(50,68,78,.8)] p-4 md:p-12"
              style={{ textShadow: "1px 1px 4px rgba(0, 0, 0, 0.5)" }}
            >
              Déjate inspirar por las imágenes que capturan la energía y el
              espíritu de colaboración de nuestra comunidad.
            </p>
          </div>
        </div>

        <div className="pt-14">
          <h2 className="text-left text-4xl font-bold py-6 pl-8 md:pl-36 text-[#B9C5D5]">
            Esto somos
          </h2>

          {/* Primera fila */}
          {/* Esto no se debe cambiar */}
          <motion.div
            ref={image1Ref}
            initial={{ opacity: 0, y: 50 }}
            animate={image1InView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.8, ease: "easeOut" }}
            className="grid grid-cols-1 md:grid-cols-2 md:gap-4 lg:gap-0 px-4 md:px-16"
          >
            <div className="flex flex-col order-1 md:order-none">
              <picture className="shadow-lg w-full h-auto">
                <source
                  srcSet="
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716597210/estos_somos_1_3b8598f8cb.webp 480w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/w_800/v1716597210/estos_somos_1_3b8598f8cb.webp 800w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto/q_auto/w_1200/v1716597210/estos_somos_1_3b8598f8cb.webp 1200w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto/q_auto/w_1600/v1716597210/estos_somos_1_3b8598f8cb.webp 1600w"
                  sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                  type="image/webp"
                />
                <source
                  srcSet="
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/w_480/v1716597210/estos_somos_1_3b8598f8cb.png 480w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/w_800/v1716597210/estos_somos_1_3b8598f8cb.png 800w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto/q_auto/w_1200/v1716597210/estos_somos_1_3b8598f8cb.png 1200w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto/q_auto/w_1600/v1716597210/estos_somos_1_3b8598f8cb.png 1600w"
                  sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                  type="image/png"
                />
                <img
                  src="https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/v1716597210/estos_somos_1_3b8598f8cb.png"
                  alt="Equipo discutiendo en una sala de conferencias"
                  className="shadow-lg w-full h-auto"
                  loading="lazy"
                />
              </picture>
            </div>
            <div className="flex flex-col justify-center order-2 md:order-none">
              <p className="text-center text-2xl text-[#D8CCB6] font-bold bg-[#32444E] p-10 md:p-10 lg:p-28 w-full">
                Descripción de la imagen breve y concisa.
              </p>
            </div>
          </motion.div>

          {/* Segunda fila */}
          <motion.div
            ref={image2Ref}
            initial={{ opacity: 0, y: 50 }}
            animate={image2InView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.8, ease: "easeOut" }}
            className="grid grid-cols-1 md:grid-cols-2 md:gap-4 lg:gap-0 px-4 md:px-16 pt-5"
          >
            <div className="flex flex-col justify-center order-2 md:order-none">
              <p className="text-center text-2xl text-[#D8CCB6] font-bold bg-[#5A6D86] p-10 md:p-10 lg:p-28 w-full">
                Descripción de la imagen breve y concisa.
              </p>
            </div>
            <div className="flex flex-col w-full order-1 md:order-none">
            <picture className="shadow-lg w-full h-auto">
  <source
    srcSet="
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716597212/estos_somos_2_2de99426f8.webp 480w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/w_800/v1716597212/estos_somos_2_2de99426f8.webp 800w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto/q_auto/w_1200/v1716597212/estos_somos_2_2de99426f8.webp 1200w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto/q_auto/w_1600/v1716597212/estos_somos_2_2de99426f8.webp 1600w"
    sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
    type="image/webp"
  />
  <source
    srcSet="
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/w_480/v1716597212/estos_somos_2_2de99426f8.png 480w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/w_800/v1716597212/estos_somos_2_2de99426f8.png 800w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto/q_auto/w_1200/v1716597212/estos_somos_2_2de99426f8.png 1200w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto/q_auto/w_1600/v1716597212/estos_somos_2_2de99426f8.png 1600w"
    sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
    type="image/png"
  />
  <img
    src="https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/v1716597212/estos_somos_2_2de99426f8.png"
    alt="Presentación de estrategias de negocio"
    className="shadow-lg w-full h-auto"
    loading="lazy"
  />
</picture>

            </div>
          </motion.div>
        </div>
        {/* Esto, la galería, sí debe cambiarse */}
        <div className="relative  pt-24 pb-12 ">
          <motion.div
            ref={galleryRef}
            initial={{ opacity: 0, y: 50 }}
            animate={galleryInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.8, ease: "easeOut" }}
            className="justify-center items-center text-center"
          >
            <h1 className="text-2xl font-semibold text-center mb-5 text-white">
              Estos somos
            </h1>
            <h2 className="text-5xl font-semibold text-center mb-5 text-[#B9C5D5]">
              #CCED
            </h2>
          </motion.div>
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4 lg:gap-0 justify-items-center">
            <div className="flex flex-col">
              <div className="flex space-x-4 mt-10 md:mr-4">
                {images.find((img) => img.id === 73) && (
                  <ImageTile
                    className="md:mr-4 lg:mr-10 w-1/6 md:w-2/5 lg:w-1/5"
                    image={images.find((img) => img.id === 73)!}
                    onSelect={() =>
                      setSelectedImage(images.find((img) => img.id === 73)!)
                    }
                  />
                )}
                <div className="flex mt-5 md:mt-10 lg:mt-28 md:transform md:scale-125">
                  {images.find((img) => img.id === 75) && (
                    <ImageTile
                      className="mt-8"
                      image={images.find((img) => img.id === 75)!}
                      onSelect={() =>
                        setSelectedImage(images.find((img) => img.id === 75)!)
                      }
                    />
                  )}
                </div>
              </div>
              <div className="flex space-x-4">
                {images.find((img) => img.id === 74) && (
                  <ImageTile
                    image={images.find((img) => img.id === 74)!}
                    onSelect={() =>
                      setSelectedImage(images.find((img) => img.id === 74)!)
                    }
                  />
                )}
                {images.find((img) => img.id === 76) && (
                  <ImageTile
                    image={images.find((img) => img.id === 76)!}
                    onSelect={() =>
                      setSelectedImage(images.find((img) => img.id === 76)!)
                    }
                  />
                )}
              </div>
            </div>

            <div className="flex flex-col items-center justify-center">
              <div className="flex mt-10 max-w-xs">
                {images.find((img) => img.id === 77) && (
                  <ImageTile
                    image={images.find((img) => img.id === 77)!}
                    onSelect={() =>
                      setSelectedImage(images.find((img) => img.id === 77)!)
                    }
                  />
                )}
              </div>
            </div>

            <div className="flex flex-col">
              <div className="flex space-x-4">
                {images.find((img) => img.id === 79) && (
                  <ImageTile
                    className="mt-16"
                    image={images.find((img) => img.id === 79)!}
                    onSelect={() =>
                      setSelectedImage(images.find((img) => img.id === 79)!)
                    }
                  />
                )}
                {images.find((img) => img.id === 81) && (
                  <ImageTile
                    className="mt-8"
                    image={images.find((img) => img.id === 81)!}
                    onSelect={() =>
                      setSelectedImage(images.find((img) => img.id === 81)!)
                    }
                  />
                )}
              </div>
              <div className="flex space-x-4">
                {images.find((img) => img.id === 78) && (
                  <ImageTile
                    image={images.find((img) => img.id === 78)!}
                    onSelect={() =>
                      setSelectedImage(images.find((img) => img.id === 78)!)
                    }
                  />
                )}
                {images.find((img) => img.id === 80) && (
                  <ImageTile
                    image={images.find((img) => img.id === 80)!}
                    onSelect={() =>
                      setSelectedImage(images.find((img) => img.id === 80)!)
                    }
                  />
                )}
              </div>
            </div>
          </div>
          <Modal
            isOpen={!!selectedImage}
            onRequestClose={() => setSelectedImage(null)}
            contentLabel="Image Modal"
            className="bg-[#B9C5D5] rounded-lg p-4 sm:p-8 max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg mx-auto my-auto overflow-hidden border-none animate__animated animate__fadeIn animate__faster"
            overlayClassName="fixed top-0 left-0 w-full h-full bg-black bg-opacity-75 flex justify-center items-center z-50 animate__animated animate__fadeIn animate__faster"
          >
            {selectedImage && (
              <>
                <img
                  src={getImageUrl(
                    selectedImage.attributes.cover.data.attributes.url
                  )}
                  alt={selectedImage.attributes.Titulo}
                  className="w-full h-auto max-h-96 object-contain"
                />
                <p className="text-center text-black mt-4">
                  {selectedImage.attributes.descripcion}
                </p>
              </>
            )}
          </Modal>
        </div>
      </div>
    </>
  );
};

export default Gallery;
