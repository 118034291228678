import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

/**
 * About component that displays information about the organization,
 * including its mission, vision, and values.
 */
const About: React.FC = () => {
  const [missionImageRef, missionImageInView] = useInView({
    triggerOnce: true,
  });
  const [missionTextRef, missionTextInView] = useInView({ triggerOnce: true });
  const [visionImageRef, visionImageInView] = useInView({ triggerOnce: true });
  const [visionTextRef, visionTextInView] = useInView({ triggerOnce: true });
  const [excellenceRef, excellenceInView] = useInView({ triggerOnce: true });
  const [collaborationRef, collaborationInView] = useInView({
    triggerOnce: true,
  });
  const [integrityRef, integrityInView] = useInView({ triggerOnce: true });
  const [innovationRef, innovationInView] = useInView({ triggerOnce: true });
  const [empathyRef, empathyInView] = useInView({ triggerOnce: true });

  return (
    <div className="w-full">
      <section className="flex flex-col lg:flex-row bg-white">
        <div
          id="about-section"
          className="relative w-full lg:w-2/3 h-96 flex items-center justify-end p-4 lg:p-0"
        >
          <picture className="absolute inset-0 w-full h-full object-cover">
            <source
              srcSet="
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1717187985/pattern_e6bf8e4b17.webp 480w,
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1717187985/pattern_e6bf8e4b17.webp 800w,
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1717187985/pattern_e6bf8e4b17.webp 1200w,
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1717187985/pattern_e6bf8e4b17.webp 1600w"
              sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
              type="image/webp"
            />
            <source
              srcSet="
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1717187985/pattern_e6bf8e4b17.png 480w,
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1717187985/pattern_e6bf8e4b17.png 800w,
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1717187985/pattern_e6bf8e4b17.png 1200w,
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1717187985/pattern_e6bf8e4b17.png 1600w"
              sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
              type="image/png"
            />
            <img
              src="https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/v1717187985/pattern_e6bf8e4b17.png"
              alt="Pattern Background"
              className="absolute inset-0 w-full h-full object-cover"
              style={{ backgroundSize: "100%" }}
              loading="lazy"
            />
          </picture>
          <div className="relative z-10 text-right mr-4 lg:mr-12">
            <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-white">
              Acerca
            </h1>
            <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-white">
              de
            </h1>
            <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-white">
              Nosotros
            </h1>
          </div>
        </div>

        <div className="relative w-full lg:w-2/4 bg-[#B9C5D5] bg-opacity-20 flex flex-col justify-center p-4 lg:py-0 h-96">
          <p className="text-gray-600 mx-4 lg:mx-10 text-left px-16">
            Somos el epicentro de conexión estratégica y directiva,
            comprometidos en facilitar el crecimiento de líderes empresariales a
            través de un espacio colaborativo donde las ideas se nutren, las
            habilidades se fortalecen y las sinergias se crean.
          </p>

          <Link to="/#consejeria">
            <button className="bg-[#B9C5D5] text-[#2B2227] font-bold py-2 px-4 rounded-full mt-4 lg:mt-0 lg:absolute lg:bottom-0 lg:right-0 lg:mr-10 mx-16 lg:ml-0">
              Lo que hacemos
            </button>
          </Link>
        </div>
      </section>

      <div className="hidden lg:flex bg-white py-8 relative">
        <div className="absolute inset-0 w-full h-full bg-[#B9C5D5] bg-opacity-20"></div>
      </div>

      <section className="w-full px-4 lg:px-8 pt-12 pb-12 bg-[#2B2227]">
        {/* Large Screen Container */}
        <div className="hidden lg:flex lg:flex-col">
          <div className="flex mb-4 lg:mx-12">
            <div className="relative w-3/5">
              <picture>
                <source
                  srcSet="
              https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716600423/mision_506efc9053.webp 480w,
              https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716600423/mision_506efc9053.webp 800w,
              https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716600423/mision_506efc9053.webp 1200w,
              https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1716600423/mision_506efc9053.webp 1600w"
                  sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                  type="image/webp"
                />
                <source
                  srcSet="
              https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716600423/mision_506efc9053.png 480w,
              https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716600423/mision_506efc9053.png 800w,
              https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716600423/mision_506efc9053.png 1200w,
              https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1716600423/mision_506efc9053.png 1600w"
                  sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                  type="image/png"
                />
                <img
                  src="https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/v1716600423/mision_506efc9053.png"
                  alt="Foto de la misión"
                  className="w-full h-auto"
                  loading="lazy"
                />
              </picture>
              <div className="absolute inset-0 bg-opacity-55 bg-[#32444E]"></div>
            </div>
            <div className="mx-2 h-auto self-stretch"></div>
            <div className="flex flex-col justify-center items-center w-full relative">
              <picture className="absolute inset-0 w-full h-full object-cover">
                <source
                  srcSet="
              https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716600430/mision_bg_7041898eeb.webp 480w,
              https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716600430/mision_bg_7041898eeb.webp 800w,
              https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716600430/mision_bg_7041898eeb.webp 1200w,
              https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1716600430/mision_bg_7041898eeb.webp 1600w"
                  sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                  type="image/webp"
                />
                <source
                  srcSet="
              https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716600430/mision_bg_7041898eeb.png 480w,
              https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716600430/mision_bg_7041898eeb.png 800w,
              https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716600430/mision_bg_7041898eeb.png 1200w,
              https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1716600430/mision_bg_7041898eeb.png 1600w"
                  sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                  type="image/png"
                />
                <img
                  src="https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/v1716600430/mision_bg_7041898eeb.png"
                  alt="Background Misión"
                  className="absolute inset-0 w-full h-full object-cover"
                  loading="lazy"
                />
              </picture>
              <div className="absolute inset-0 bg-opacity-85 bg-[#2B2227]"></div>
              <div className="p-20 z-10 mx-16 relative">
                <h2 className="text-4xl text-white font-bold">Misión</h2>
                <p className="text-white text-justify">
                  Facilitar el crecimiento y la excelencia empresarial en la
                  región de Sonora, consolidándose como el epicentro de conexión
                  estratégica y directiva. Trabajamos incansablemente para
                  proporcionar un espacio colaborativo donde los líderes
                  empresariales fortalezcan sus habilidades, generen ideas
                  innovadoras y fomenten la creación de sinergias, contribuyendo
                  así al desarrollo sostenible de sus empresas y la prosperidad
                  de la comunidad.
                </p>
              </div>
            </div>
          </div>
          <div className="flex mx-12">
            <div className="flex mb-8 flex-col justify-center items-center w-full relative">
              <picture className="absolute inset-0 w-full h-full object-cover">
                <source
                  srcSet="
              https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716600431/vision_bg_0dce121bbc.webp 480w,
              https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716600431/vision_bg_0dce121bbc.webp 800w,
              https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716600431/vision_bg_0dce121bbc.webp 1200w,
              https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1716600431/vision_bg_0dce121bbc.webp 1600w"
                  sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                  type="image/webp"
                />
                <source
                  srcSet="
              https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716600431/vision_bg_0dce121bbc.png 480w,
              https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716600431/vision_bg_0dce121bbc.png 800w,
              https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716600431/vision_bg_0dce121bbc.png 1200w,
              https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1716600431/vision_bg_0dce121bbc.png 1600w"
                  sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                  type="image/png"
                />
                <img
                  src="https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/v1716600431/vision_bg_0dce121bbc.png"
                  alt="Background Visión"
                  className="absolute inset-0 w-full h-full object-cover"
                  loading="lazy"
                />
              </picture>
              <div className="absolute inset-0 bg-opacity-85 bg-[#2B2227]"></div>
              <div className="p-20 z-10 mx-16 relative">
                <h2 className="text-4xl text-white font-bold">Visión</h2>
                <p className="text-white text-justify">
                  Ser reconocidos como el referente indiscutible en el
                  fortalecimiento del liderazgo empresarial en Sonora. Aspiramos
                  a ser el catalizador que transforma la región, impulsando una
                  cultura empresarial proactiva. Buscamos ser el punto de
                  encuentro preferido para líderes visionarios, donde la
                  colaboración, la innovación y el crecimiento empresarial se
                  fusionan para impulsar el desarrollo económico sostenible.
                </p>
              </div>
            </div>
            <div className="mx-2 h-auto self-stretch"></div>
            <div className="relative mb-8 w-3/5">
              <picture>
                <source
                  srcSet="
              https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716600421/vision_d46d2de764.webp 480w,
              https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716600421/vision_d46d2de764.webp 800w,
              https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716600421/vision_d46d2de764.webp 1200w,
              https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1716600421/vision_d46d2de764.webp 1600w"
                  sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                  type="image/webp"
                />
                <source
                  srcSet="
              https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716600421/vision_d46d2de764.png 480w,
              https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716600421/vision_d46d2de764.png 800w,
              https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716600421/vision_d46d2de764.png 1200w,
              https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1716600421/vision_d46d2de764.png 1600w"
                  sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                  type="image/png"
                />
                <img
                  src="https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/v1716600421/vision_d46d2de764.png"
                  alt="Foto de la visión"
                  className="w-full h-auto"
                  loading="lazy"
                />
              </picture>
              <div className="absolute inset-0 bg-opacity-55 bg-[#32444E]"></div>
            </div>
          </div>
        </div>

        {/* Small Screen Container */}
        <div className="lg:hidden flex flex-col gap-4 mx-4">
          <motion.div
            ref={missionImageRef}
            initial={{ opacity: 0, y: 50 }}
            animate={missionImageInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.8, ease: "easeOut" }}
            className="relative w-full mb-4"
          >
            <picture>
              <source
                srcSet="
            https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716600423/mision_506efc9053.webp 480w,
            https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716600423/mision_506efc9053.webp 800w,
            https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716600423/mision_506efc9053.webp 1200w,
            https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1716600423/mision_506efc9053.webp 1600w"
                sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                type="image/webp"
              />
              <source
                srcSet="
            https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716600423/mision_506efc9053.png 480w,
            https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716600423/mision_506efc9053.png 800w,
            https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716600423/mision_506efc9053.png 1200w,
            https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1716600423/mision_506efc9053.png 1600w"
                sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                type="image/png"
              />
              <img
                src="https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/v1716600423/mision_506efc9053.png"
                alt="Foto de la misión"
                className="w-full h-auto"
                loading="lazy"
              />
            </picture>
            <div className="absolute inset-0 bg-opacity-55 bg-[#32444E]"></div>
          </motion.div>
          <motion.div
            ref={missionTextRef}
            initial={{ opacity: 0, x: -50 }}
            animate={missionTextInView ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 0.8, ease: "easeOut" }}
            className="flex flex-col justify-center items-center relative mb-4"
          >
            <picture className="absolute inset-0 w-full h-full object-cover">
              <source
                srcSet="
            https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716600430/mision_bg_7041898eeb.webp 480w,
            https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716600430/mision_bg_7041898eeb.webp 800w,
            https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716600430/mision_bg_7041898eeb.webp 1200w,
            https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1716600430/mision_bg_7041898eeb.webp 1600w"
                sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                type="image/webp"
              />
              <source
                srcSet="
            https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716600430/mision_bg_7041898eeb.png 480w,
            https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716600430/mision_bg_7041898eeb.png 800w,
            https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716600430/mision_bg_7041898eeb.png 1200w,
            https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1716600430/mision_bg_7041898eeb.png 1600w"
                sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                type="image/png"
              />
              <img
                src="https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/v1716600430/mision_bg_7041898eeb.png"
                alt="Background Misión"
                className="absolute inset-0 w-full h-full object-cover"
                loading="lazy"
              />
            </picture>
            <div className="absolute inset-0 bg-opacity-85 bg-[#2B2227]"></div>
            <div className="p-8 lg:p-20 z-10 mx-4 lg:mx-16 relative">
              <h2 className="text-3xl lg:text-4xl text-white font-bold">
                Misión
              </h2>
              <p className="text-white text-justify">
                Facilitar el crecimiento y la excelencia empresarial en la
                región de Sonora, consolidándose como el epicentro de conexión
                estratégica y directiva. Trabajamos incansablemente para
                proporcionar un espacio colaborativo donde los líderes
                empresariales fortalezcan sus habilidades, generen ideas
                innovadoras y fomenten la creación de sinergias, contribuyendo
                así al desarrollo sostenible de sus empresas y la prosperidad de
                la comunidad.
              </p>
            </div>
          </motion.div>
          <motion.div
            ref={visionImageRef}
            initial={{ opacity: 0, y: 50 }}
            animate={visionImageInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.8, ease: "easeOut" }}
            className="relative w-full mb-4"
          >
            <picture>
              <source
                srcSet="
            https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716600421/vision_d46d2de764.webp 480w,
            https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716600421/vision_d46d2de764.webp 800w,
            https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716600421/vision_d46d2de764.webp 1200w,
            https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1716600421/vision_d46d2de764.webp 1600w"
                sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                type="image/webp"
              />
              <source
                srcSet="
            https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716600421/vision_d46d2de764.png 480w,
            https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716600421/vision_d46d2de764.png 800w,
            https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716600421/vision_d46d2de764.png 1200w,
            https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1716600421/vision_d46d2de764.png 1600w"
                sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                type="image/png"
              />
              <img
                src="https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/v1716600421/vision_d46d2de764.png"
                alt="Foto de la visión"
                className="w-full h-auto"
                loading="lazy"
              />
            </picture>
            <div className="absolute inset-0 bg-opacity-55 bg-[#32444E]"></div>
          </motion.div>
          <motion.div
            ref={visionTextRef}
            initial={{ opacity: 0, x: 50 }}
            animate={visionTextInView ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 0.8, ease: "easeOut" }}
            className="flex flex-col justify-center items-center relative"
          >
            <picture className="absolute inset-0 w-full h-full object-cover">
              <source
                srcSet="
            https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716600431/vision_bg_0dce121bbc.webp 480w,
            https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716600431/vision_bg_0dce121bbc.webp 800w,
            https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716600431/vision_bg_0dce121bbc.webp 1200w,
            https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1716600431/vision_bg_0dce121bbc.webp 1600w"
                sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                type="image/webp"
              />
              <source
                srcSet="
            https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716600431/vision_bg_0dce121bbc.png 480w,
            https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716600431/vision_bg_0dce121bbc.png 800w,
            https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716600431/vision_bg_0dce121bbc.png 1200w,
            https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1716600431/vision_bg_0dce121bbc.png 1600w"
                sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                type="image/png"
              />
              <img
                src="https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/v1716600431/vision_bg_0dce121bbc.png"
                alt="Background Visión"
                className="absolute inset-0 w-full h-full object-cover"
                loading="lazy"
              />
            </picture>
            <div className="absolute inset-0 bg-opacity-85 bg-[#2B2227]"></div>
            <div className="p-8 lg:p-20 z-10 mx-4 lg:mx-16 relative">
              <h2 className="text-3xl lg:text-4xl text-white font-bold">
                Visión
              </h2>
              <p className="text-white text-justify">
                Ser reconocidos como el referente indiscutible en el
                fortalecimiento del liderazgo empresarial en Sonora. Aspiramos a
                ser el catalizador que transforma la región, impulsando una
                cultura empresarial proactiva. Buscamos ser el punto de
                encuentro preferido para líderes visionarios, donde la
                colaboración, la innovación y el crecimiento empresarial se
                fusionan para impulsar el desarrollo económico sostenible.
              </p>
            </div>
          </motion.div>
        </div>
      </section>

      <section className="bg-[rgba(50,68,78,0.5)] text-white px-4 sm:px-8 md:px-2 pt-16 pb-16 lg:pt-24 lg:pb-24 relative">
        <img
          src="/static/valores-1.png"
          alt="Valores"
          className="absolute right-0 top-0"
          loading="lazy"
        />

        <img
          src="/static/valores-1.png"
          alt="Valores"
          className="absolute right-0 top-0"
          loading="lazy"
        />

        <img
          src="/static/valores-4.png"
          alt="Valores 2"
          className="absolute left-0 bottom-0"
          loading="lazy"
        />

        <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-center pb-8 lg:pb-12">
          Nuestros valores
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6 lg:gap-8 pb-12 pt-12 px-24">
          <motion.div
            ref={excellenceRef}
            initial={{ opacity: 0, y: 50 }}
            animate={excellenceInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.8, ease: "easeOut" }}
            className="border-l-4 border-[#B9C5D5] pl-4 pb-8"
          >
            <h3 className="text-[#597276] font-bold text-xl md:text-2xl pb-4">
              EXCELENCIA
            </h3>
            <p>
              Buscamos la excelencia en todas nuestras actividades, brindando
              servicios y recursos de la más alta calidad para contribuir al
              éxito empresarial.
            </p>
          </motion.div>
          <motion.div
            ref={collaborationRef}
            initial={{ opacity: 0, y: 50 }}
            animate={collaborationInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.8, ease: "easeOut" }}
            className="border-l-4 border-[#B9C5D5] pl-4 pb-8"
          >
            <h3 className="text-[#597276] font-bold text-xl md:text-2xl pb-4">
              COLABORACIÓN
            </h3>
            <p>
              Fomentamos un ambiente de colaboración activa, donde la
              interconexión entre líderes empresariales se traduzca en
              oportunidades y soluciones innovadoras.
            </p>
          </motion.div>
          <motion.div
            ref={integrityRef}
            initial={{ opacity: 0, y: 50 }}
            animate={integrityInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.8, ease: "easeOut" }}
            className="border-l-4 border-[#B9C5D5] pl-4 pb-8"
          >
            <h3 className="text-[#597276] font-bold text-xl md:text-2xl pb-4">
              INTEGRIDAD
            </h3>
            <p>
              Actuamos con honestidad, transparencia y ética en todas nuestras
              interacciones, construyendo relaciones basadas en la confianza y
              la responsabilidad.
            </p>
          </motion.div>
          <motion.div
            ref={innovationRef}
            initial={{ opacity: 0, y: 50 }}
            animate={innovationInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.8, ease: "easeOut" }}
            className="border-l-4 border-[#B9C5D5] pl-4 pb-8"
          >
            <h3 className="text-[#597276] font-bold text-xl md:text-2xl pb-4">
              INNOVACIÓN
            </h3>
            <p>
              Promovemos la creatividad y la adopción de nuevas ideas,
              tecnologías y prácticas empresariales para impulsar la innovación
              continua en el tejido empresarial.
            </p>
          </motion.div>
          <motion.div
            ref={empathyRef}
            initial={{ opacity: 0, y: 50 }}
            animate={empathyInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.8, ease: "easeOut" }}
            className="border-l-4 border-[#B9C5D5] pl-4 pb-8"
          >
            <h3 className="text-[#597276] font-bold text-xl md:text-2xl pb-4">
              EMPATÍA
            </h3>
            <p>
              Nos comprometemos a comprender y atender las necesidades de
              nuestros miembros, adaptándonos de manera proactiva para brindar
              soluciones efectivas y personalizadas.
            </p>
          </motion.div>
        </div>
      </section>
    </div>
  );
};

export default About;
