import React, { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faTwitter,
  faWhatsapp,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import Modal from "react-modal";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import Loader from "../components/Loader";
import ArticleCard from "../components/ArticleCard";
import { Article } from "../utils/interfaces";
import { motion, AnimatePresence } from "framer-motion";
import NotFoundArticle from "../pages/NotFoundArticle";
import LikeButton from "../components/LikeButton";
import CommentSection from "../components/CommentSection"; // Importar CommentSection

Modal.setAppElement("#root");

const ArticleDetail: React.FC = () => {
  const location = useLocation();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const currentUrl = window.location.origin + location.pathname;
  const { articleId, articleTitle } = useParams<{
    articleId: string;
    articleTitle: string;
  }>();
  const [article, setArticle] = useState<Article | null>(null);
  const [loading, setLoading] = useState(true);
  const [recentArticles, setRecentArticles] = useState<Article[]>([]);
  const navigate = useNavigate();
  const socialMediaPlatforms = [
    {
      icon: faFacebook,
      url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        currentUrl
      )}`,
    },
    {
      icon: faTwitter,
      url: `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        currentUrl
      )}`,
    },
    {
      icon: faWhatsapp,
      url: `https://api.whatsapp.com/send?text=${encodeURIComponent(
        currentUrl
      )}`,
    },
    {
      icon: faLinkedin,
      url: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
        currentUrl
      )}`,
    },
    {
      icon: faCopy,
      action: () => {
        navigator.clipboard
          .writeText(currentUrl)
          .then(() => {
            setModalIsOpen(true);
          })
          .catch((err) => {
            console.error("Error al copiar el enlace: ", err);
          });
      },
    },
  ];

  const getImageUrl = (url: string) => {
    const params = new URLSearchParams({ f_auto: "true", q_auto: "true" });
    if (url.startsWith("http")) {
      return `${url}?${params.toString()}`;
    }
    return `${process.env.REACT_APP_API_URL}${url}?${params.toString()}`;
  };

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/articulos/${articleId}?populate[imagen]=url&populate[autor][populate]=nombre,imagen&populate[tags]=nombre,likes&populate[comments][populate]=content,likes,replies[populate]=content,likes`
        );
        const json = await response.json();
        console.log("Fetched article JSON:", json);

        if (!json.data) {
          throw new Error("Article not found");
        }

        const formattedArticle = {
          id: json.data.id,
          title: json.data.attributes.titulo,
          content: json.data.attributes.contenido,
          publishedAt: json.data.attributes.publishedAt,
          image: json.data.attributes.imagen?.data?.[0]?.attributes,
          author: {
            name: json.data.attributes.autor.data.attributes.nombre,
            image:
              json.data.attributes.autor.data.attributes.imagen?.data?.[0]
                ?.attributes,
          },
          tags: json.data.attributes.tags.data.map((tag: any) => ({
            name: tag.attributes.nombre,
          })),
          likes: json.data.attributes.likes,
          comments: json.data.attributes.comments
            ? json.data.attributes.comments.data.map((comment: any) => ({
                id: comment.id,
                content: comment.attributes.content,
                likes: comment.attributes.likes,
                replies: comment.attributes.replies
                  ? comment.attributes.replies.data.map((reply: any) => ({
                      id: reply.id,
                      content: reply.attributes.content,
                      likes: reply.attributes.likes,
                    }))
                  : [],
              }))
            : [],
        };

        const formatTitleForUrl = (title: string) =>
          title
            .toLowerCase()
            .replace(/ /g, "-")
            .replace(/[^\w-]+/g, "");

        if (formatTitleForUrl(formattedArticle.title) !== articleTitle) {
          throw new Error("Article title does not match");
        }

        setArticle(formattedArticle);
        document.title = `${formattedArticle.title} - Insights - CCED`;
      } catch (error) {
        console.error("Error fetching article:", error);
        setArticle(null);
      } finally {
        setTimeout(() => setLoading(false), 500);
      }
    };

    const fetchRecentArticles = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/articulos?populate[imagen]=url&populate[autor][populate]=nombre,imagen&populate[tags]=nombre`
        );
        const json = await response.json();
        console.log("Fetched recent articles JSON:", json);

        const articles: Article[] = json.data.map((item: any) => ({
          id: item.id,
          title: item.attributes.titulo,
          content: item.attributes.contenido,
          publishedAt: item.attributes.publishedAt,
          image: item.attributes.imagen?.data?.[0]?.attributes,
          author: {
            name: item.attributes.autor.data.attributes.nombre,
            image:
              item.attributes.autor.data.attributes.imagen?.data?.[0]
                ?.attributes,
          },
          tags: item.attributes.tags.data.map((tag: any) => ({
            name: tag.attributes.nombre,
          })),
        }));
        setRecentArticles(articles.slice(0, 3));
      } catch (error) {
        console.error("Error fetching recent articles:", error);
        navigate("/not-found");
      }
    };

    fetchArticle();
    fetchRecentArticles();
  }, [articleId, articleTitle, navigate]);

  if (loading) {
    return (
      <motion.div
        key="loader"
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.9 }}
        transition={{ duration: 0.5 }}
      >
        <Loader />
      </motion.div>
    );
  }

  if (!article) {
    return <NotFoundArticle />;
  }

  return (
    <AnimatePresence mode="wait">
      {loading ? (
        <motion.div
          key="loader"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.9 }}
          transition={{ duration: 0.5 }}
        >
          <Loader />
        </motion.div>
      ) : (
        <motion.article
          key="article"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.5 }}
        >
          <div className="article-detail-container w-full mx-auto pb-12 shadow-lg rounded-lg flex flex-col gap-6 bg-">
            <div className="w-full bg-[#d8ccb6] bg-opacity-10 flex flex-col md:flex-row">
              <div className="w-full md:w-4/5">
                <div className="image-container w-full flex">
                  {article.image?.url && (
                    <picture className="object-cover w-full h-80 opacity-80">
                      <source
                        srcSet={`
                ${getImageUrl(article.image.url)}?format=webp&w=480 480w,
                ${getImageUrl(article.image.url)}?format=webp&w=800 800w,
                ${getImageUrl(article.image.url)}?format=webp&w=1200 1200w,
                ${getImageUrl(article.image.url)}?format=webp&w=1600 1600w
              `}
                        sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                        type="image/webp"
                      />
                      <source
                        srcSet={`
                ${getImageUrl(article.image.url)}?w=480 480w,
                ${getImageUrl(article.image.url)}?w=800 800w,
                ${getImageUrl(article.image.url)}?w=1200 1200w,
                ${getImageUrl(article.image.url)}?w=1600 1600w
              `}
                        sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                        type="image/jpeg"
                      />
                      <img
                        src={getImageUrl(article.image.url)}
                        alt={
                          article.image.alternativeText || "Imagen del Artículo"
                        }
                        className="object-cover w-full h-80 opacity-80"
                        loading="lazy"
                      />
                    </picture>
                  )}
                </div>
                <div className="bg-[#534d47] pt-10 pb-10">
                  <header className="text-center">
                    <h1 className="text-5xl px-6 md:px-12 lg:px-24 font-bold text-white pb-10 pt-5">
                      {article.title}
                    </h1>
                  </header>

                  <section className="w-full prose prose-lg prose-invert text-lg leading-relaxed text-white px-4 md:px-10 lg:px-20">
                    <ReactMarkdown
                      remarkPlugins={[remarkGfm]}
                      rehypePlugins={[rehypeRaw]}
                    >
                      {article.content}
                    </ReactMarkdown>
                  </section>
                </div>
              </div>

              <aside className="flex flex-col px-12 py-12 justify-between items-center w-full md:w-1/5">
                <div className="text-center">
                  {article.author.image?.url && (
                    <picture className="w-16 h-16 rounded-full mx-auto mb-4">
                      <source
                        srcSet={`
                ${getImageUrl(article.author.image.url)}?format=webp&w=48 48w,
                ${getImageUrl(article.author.image.url)}?format=webp&w=96 96w
              `}
                        sizes="48px"
                        type="image/webp"
                      />
                      <source
                        srcSet={`
                ${getImageUrl(article.author.image.url)}?w=48 48w,
                ${getImageUrl(article.author.image.url)}?w=96 96w
              `}
                        sizes="48px"
                        type="image/jpeg"
                      />
                      <img
                        src={getImageUrl(article.author.image.url)}
                        alt={article.author.name}
                        className="w-16 h-16 rounded-full mx-auto mb-4"
                        loading="lazy"
                      />
                    </picture>
                  )}
                  <p className="font-semibold text-white">
                    {article.author.name}
                  </p>
                  <p className="text-sm text-gray-500">
                    Publicado el:{" "}
                    {new Date(article.publishedAt).toLocaleDateString()}
                  </p>
                  <div className="tags flex flex-wrap justify-center mt-2 mb-4">
                    {article.tags.map((tag, index) => (
                      <span
                        key={index}
                        className="bg-gray-200 text-gray-800 text-sm font-medium mr-2 px-3 py-1 rounded-full m-2"
                      >
                        {tag.name}
                      </span>
                    ))}
                  </div>
                  <div className="flex justify-center mt-3 mb-3">
                    <LikeButton
                      articleId={article.id}
                      initialLikes={article.likes}
                    />
                  </div>

                  <div className="flex flex-col items-center space-y-2">
                    <h3 className="text-lg font-semibold text-white mb-2">
                      Compartir
                    </h3>
                    <div className="flex flex-row md:flex-col items-center space-x-2 md:space-x-0 md:space-y-2">
                      {socialMediaPlatforms.map((platform, index) =>
                        platform.url ? (
                          <a
                            key={index}
                            href={platform.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-white hover:text-gray-300 text-3xl"
                          >
                            <FontAwesomeIcon icon={platform.icon} />
                          </a>
                        ) : (
                          <button
                            key={index}
                            onClick={platform.action}
                            className="text-white hover:text-gray-300 focus:outline-none text-3xl"
                          >
                            <FontAwesomeIcon icon={platform.icon} />
                          </button>
                        )
                      )}
                    </div>
                    <Modal
                      isOpen={modalIsOpen}
                      onRequestClose={() => setModalIsOpen(false)}
                      className="fixed inset-0 z-50 overflow-auto bg-smoke-light flex items-center justify-center"
                      overlayClassName="fixed inset-0 bg-black opacity-80"
                    >
                      <div className="relative bg-white w-full max-w-md p-8 rounded-2xl">
                        <h2 className="text-xl font-semibold mb-4 text-center">
                          Enlace Copiado al Portapapeles
                        </h2>
                        <button
                          onClick={() => setModalIsOpen(false)}
                          className="absolute top-2 right-2 p-2 text-lg font-bold text-gray-600 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-400"
                          aria-label="Close modal"
                        >
                          &times;
                        </button>
                      </div>
                    </Modal>
                  </div>
                </div>
              </aside>
            </div>

            <CommentSection articleId={articleId!} />

            <section className="w-full px-4 ">
              <h2 className="text-3xl font-bold mb-8 px-4 text-white">
                Artículos recientes del blog
              </h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 pb-12">
                {recentArticles.map((article) => (
                  <ArticleCard article={article} key={article.id} />
                ))}
              </div>
            </section>
          </div>
        </motion.article>
      )}
    </AnimatePresence>
  );
};

export default ArticleDetail;
