import { BrowserRouter as Router } from 'react-router-dom';
import { SpeedInsights } from "@vercel/speed-insights/react"; // Asegúrate de que esta es la importación correcta
import Header from './components/Header';
import Footer from './components/Footer';
import AnimatedRoutes from './components/AnimatedRoutes';

const App = () => {
  return (
    <Router basename="/">
      <div className="bg-[#2b2227] min-h-screen flex flex-col">
        <Header />
        <SpeedInsights /> {/* Aquí integras el componente SpeedInsights */}
        <main className="flex-grow bg-[#2b2227]">
          <AnimatedRoutes />
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
