import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

/**
 * Memberships component that displays information about different membership
 * plans offered by the organization, including subscription details and benefits.
 */
const Memberships: React.FC = () => {
  const [heroRef, heroInView] = useInView({ triggerOnce: true });
  const [linkUpRef, linkUpInView] = useInView({ triggerOnce: true });
  const [annualRef, annualInView] = useInView({ triggerOnce: true });

  return (
    <div className="w-full mt-6 md:mt-12">
      <section id="membership-section" className="flex flex-col w-full">
        <div className="flex flex-col lg:flex-row">
          {/* Membership introduction section */}
          <motion.div
            ref={heroRef}
            initial={{ opacity: 0, y: 50 }}
            animate={heroInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.8, ease: "easeOut" }}
            className="w-full lg:w-1/2 bg-[#32444E] px-10 lg:px-20 py-14 lg:py-28 relative"
          >
            <img
              src="/static/capa10.png"
              alt="Valores 2"
              className="absolute right-0 bottom-0"
              loading="lazy"
              id="membership-intro-image"
            />
            <div className="text-left ml-6 lg:ml-12">
              <h1
                id="membership-intro-title"
                className="text-3xl lg:text-5xl font-bold text-[#B9C5D5]"
              >
                Explora nuestras membresías y haz avanzar tu carrera
                empresarial.
              </h1>
            </div>
          </motion.div>

          {/* Background image section */}
          <div
            id="membership-background-section"
            className="relative w-full lg:w-1/2 h-64 lg:h-auto flex flex-col order-first lg:order-none"
          >
            <picture className="absolute inset-0 w-full h-full object-cover">
              <source
                srcSet="
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716600660/mem_hero_67c452b9fa.webp 480w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716600660/mem_hero_67c452b9fa.webp 800w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716600660/mem_hero_67c452b9fa.webp 1200w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1716600660/mem_hero_67c452b9fa.webp 1600w"
                sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                type="image/webp"
              />
              <source
                srcSet="
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716600660/mem_hero_67c452b9fa.png 480w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716600660/mem_hero_67c452b9fa.png 800w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716600660/mem_hero_67c452b9fa.png 1200w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/w_1600/v1716600660/mem_hero_67c452b9fa.png 1600w"
                sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                type="image/png"
              />
              <img
                src="https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/v1716600660/mem_hero_67c452b9fa.png"
                alt="Background"
                className="absolute inset-0 w-full h-full object-cover"
                loading="lazy"
                id="membership-background-image"
              />
            </picture>

            <div className="absolute inset-0 bg-gradient-to-b from-[#2B2227] via-transparent"></div>
            <div className="absolute inset-0 bg-opacity-40 bg-[#2B2227]"></div>
          </div>
        </div>
        <div id="membership-content" className="w-full p-8 text-right"></div>
      </section>

      <section className="lg:ml-40">
        <div className="flex flex-wrap lg:flex-nowrap">
          {/* LinkUp subscription section */}
          <motion.div
            ref={linkUpRef}
            initial={{ opacity: 0, y: 50 }}
            animate={linkUpInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.8, ease: "easeOut" }}
            className="w-full lg:w-1/2 flex flex-col justify-center bg-[rgba(90,96,104,0.2)] p-8 lg:p-16 text-white relative"
          >
            <h2 className="text-3xl lg:text-4xl font-bold text-center leading-tight">
              Suscripción a plataforma
            </h2>
            <h2 className="text-3xl mt-2 lg:text-4xl font-bold text-center leading-tight">
              LINK UP
            </h2>
            <p className="text-xl lg:text-2xl my-4 text-center text-[#D8CCB6]">
              $250.00/mes
            </p>
            <button className="px-4 py-2 lg:px-6 lg:py-3 mx-8 md:mx-auto lg:mx-auto my-4 text-black font-bold bg-[#B9C5D5] rounded-full hover:bg-[#838c97] focus:outline-none transition ease-in duration-200">
              INSCRÍBETE
            </button>

            <div className="container flex justify-center">
              <ul className="list-disc space-y-2 text-left">
                <li>
                  Acceso ilimitado a todas nuestras actividades y eventos.
                </li>
                <li>Acceso a nuestra herramienta 'LinkUp'.</li>
                <li>Descuentos exclusivos en cursos y capacitaciones.</li>
              </ul>
            </div>

            <div className="footer-simulado absolute bottom-0 left-0 w-full bg-[#32444E] p-10 text-white text-center hidden lg:block"></div>
          </motion.div>

          {/* Image section */}
          <div className="relative w-full lg:w-1/2 lg:justify-end flex mt-8 lg:mt-0">
            <picture className="w-full h-auto object-cover">
              <source
                srcSet="
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716600656/Capa_1_c556175a92.webp 480w,
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716600656/Capa_1_c556175a92.webp 800w,
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716600656/Capa_1_c556175a92.webp 1200w,
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1716600656/Capa_1_c556175a92.webp 1600w"
                sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                type="image/webp"
              />
              <source
                srcSet="
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716600656/Capa_1_c556175a92.png 480w,
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716600656/Capa_1_c556175a92.png 800w,
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716600656/Capa_1_c556175a92.png 1200w,
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/w_1600/v1716600656/Capa_1_c556175a92.png 1600w"
                sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                type="image/png"
              />
              <img
                src="https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/v1716600656/Capa_1_c556175a92.png"
                alt="Descripción de la imagen"
                className="w-full h-auto object-cover"
                loading="lazy"
              />
            </picture>
          </div>
        </div>

        {/* Footer visible only on large screens */}
        <div className="w-full bg-[#32444E] p-10 text-center text-black hidden lg:block">
          {/* Simulación de altura */}
        </div>

        <div className="footer-simulado w-full bg-[#2B2227] p-5 text-white text-center lg:hidden"></div>
      </section>

      <div className="footer-simulado w-full bg-[#2B2227] p-10 text-white text-center hidden md:block"></div>

      <section className="flex flex-wrap">
        <div className="w-full">
          <div className="flex flex-wrap flex-col lg:flex-row">
            {/* Left column with image */}
            <div className="w-full lg:w-1/2 relative hidden md:block">
              <picture className="w-full h-full object-cover">
                <source
                  srcSet="
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716600658/Capa_2_5436c51cd2.webp 480w,
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716600658/Capa_2_5436c51cd2.webp 800w,
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716600658/Capa_2_5436c51cd2.webp 1200w,
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/w_1600/v1716600658/Capa_2_5436c51cd2.webp 1600w"
                  sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                  type="image/webp"
                />
                <source
                  srcSet="
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716600658/Capa_2_5436c51cd2.png 480w,
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716600658/Capa_2_5436c51cd2.png 800w,
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/w_1200/v1716600658/Capa_2_5436c51cd2.png 1200w,
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/w_1600/v1716600658/Capa_2_5436c51cd2.png 1600w"
                  sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                  type="image/png"
                />
                <img
                  src="https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/v1716600658/Capa_2_5436c51cd2.png"
                  alt="Descripción de la imagen"
                  className="w-full h-full object-cover"
                  loading="lazy"
                />
              </picture>
            </div>

            {/* Right column with membership content and sidebar */}
            <motion.div
              ref={annualRef}
              initial={{ opacity: 0, y: 50 }}
              animate={annualInView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.8, ease: "easeOut" }}
              className="w-full lg:w-1/2 flex flex-col lg:h-screen"
            >
              <div className="flex-grow flex flex-col items-center justify-center">
                {/* Membership content */}
                <div className="flex w-full lg:h-3/4">
                  <div className="w-full flex flex-col justify-center bg-[rgba(90,96,104,0.2)] p-8 lg:p-16 text-white relative">
                    <h2 className="text-3xl lg:text-4xl font-bold text-center leading-tight">
                      Membresía Anual
                    </h2>
                    <p className="text-xl my-4 text-center text-[#D8CCB6]">
                      $10,000.00
                    </p>
                    <button className="px-4 py-2 lg:px-6 lg:py-3 mx-8 md:mx-auto lg:mx-auto my-4 text-black font-bold bg-[#B9C5D5] rounded-full hover:bg-[#838c97] focus:outline-none transition ease-in duration-200">
                      INVIERTE YA
                    </button>
                    <div className="container flex justify-center">
                      <ul className="list-disc space-y-2 text-left">
                        <li>
                          Acceso ilimitado a todas nuestras actividades y
                          eventos.
                        </li>
                        <li>Acceso a nuestra herramienta 'LinkUp'.</li>
                        <li>
                          Descuentos exclusivos en cursos y capacitaciones.
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* Sidebar */}
                  <div className="w-full lg:w-1/4 bg-[#32444E] p-5 text-white text-left lg:block hidden"></div>
                </div>
                <div className="w-full lg:w-1/2 relative md:hidden">
                  <picture className="w-full h-full object-cover">
                    <source
                      srcSet="
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716600658/Capa_2_5436c51cd2.webp 480w,
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716600658/Capa_2_5436c51cd2.webp 800w,
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716600658/Capa_2_5436c51cd2.webp 1200w,
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/w_1600/v1716600658/Capa_2_5436c51cd2.webp 1600w"
                      sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                      type="image/webp"
                    />
                    <source
                      srcSet="
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716600658/Capa_2_5436c51cd2.png 480w,
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716600658/Capa_2_5436c51cd2.png 800w,
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/w_1200/v1716600658/Capa_2_5436c51cd2.png 1200w,
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/w_1600/v1716600658/Capa_2_5436c51cd2.png 1600w"
                      sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                      type="image/png"
                    />
                    <img
                      src="https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/v1716600658/Capa_2_5436c51cd2.png"
                      alt="Descripción de la imagen"
                      className="w-full h-full object-cover"
                      loading="lazy"
                    />
                  </picture>
                </div>

                {/* Footer spanning both columns */}
                <div className="flex lg:py-12 items-center w-full lg:h-1/4 bg-[#2B2227] text-[#D8CCB6] text-left px-5 lg:px-10 mt-8 lg:mt-0">
                  <p className="text-lg lg:text-left">
                    Para más detalles sobre el proceso de ingreso, no dudes en
                    contactarnos al teléfono{" "}
                    <span className="font-bold">6622576731</span> o escribirnos
                    a <span className="font-bold">membresias@cced.mx</span>
                  </p>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      <section className="w-full bg-[#2B2227] p-6 md:p-10 mt-10 text-white text-center pb-8 md:pb-12">
        <p className="text-xl md:text-3xl text-[#D8CCB6] mb-4 font-bold">
          ¡Esperamos darte la bienvenida pronto a nuestra comunidad empresarial!
        </p>
        <button className="bg-[#597276] text-white font-bold py-2 md:py-3 px-4 md:px-6 rounded-full hover:bg-gray-200 hover:text-gray-800 transition duration-300 ease-in-out mt-4 md:mt-8">
          Descarga nuestro Brochure
        </button>
      </section>
    </div>
  );
};

export default Memberships;
