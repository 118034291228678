import { State, Action } from '../utils/interfaces';

// Define the initial state for the reducer
export const initialState: State = {
  articles: [],
  currentPage: 1,
  direction: 0,
  allTags: [],
  searchTerm: "",
  selectedTags: {},
  selectAll: true,
  isMenuVisible: false,
  recentArticles: [],
  filteredArticles: [],
  noResults: false,
};

/**
 * Reducer function to manage the state transitions based on dispatched actions.
 *
 * @param state - The current state of the application.
 * @param action - The action dispatched which contains the type and payload.
 * @returns The new state after applying the action.
 */
export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "SET_ARTICLES":
      return { ...state, articles: action.payload };

    case "SET_CURRENT_PAGE":
      return { ...state, currentPage: action.payload };

    case "SET_DIRECTION":
      return { ...state, direction: action.payload };

    case "SET_ALL_TAGS":
      return { ...state, allTags: action.payload };

    case "SET_SEARCH_TERM":
      return { ...state, searchTerm: action.payload };

    case "SET_SELECTED_TAGS":
      return { ...state, selectedTags: action.payload };

    case "TOGGLE_SELECT_ALL":
      return { ...state, selectAll: !state.selectAll };

    case "TOGGLE_MENU_VISIBILITY":
      return { ...state, isMenuVisible: !state.isMenuVisible };

    case "SET_RECENT_ARTICLES":
      return { ...state, recentArticles: action.payload };

    case "UPDATE_TAGS":
      return {
        ...state,
        selectedTags: action.payload.selectedTags,
        selectAll: action.payload.selectAll,
        filteredArticles: action.payload.filteredArticles,
      };

    case "SET_FILTERED_ARTICLES":
      return { ...state, filteredArticles: action.payload };

    case "SET_NO_RESULTS":
      return { ...state, noResults: action.payload };

    default:
      return state;
  }
}
export {};
