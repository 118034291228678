import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessage } from "@fortawesome/free-regular-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);
const Home: React.FC = () => {
  const [heroRef, heroInView] = useInView({ triggerOnce: true });
  const [communityRef, communityInView] = useInView({ triggerOnce: true });
  const [counselingRef, counselingInView] = useInView({ triggerOnce: true });
  const [newsRef, newsInView] = useInView({ triggerOnce: true });
  const [coursesRef, coursesInView] = useInView({ triggerOnce: true });
  const [linkUpRef, linkUpInView] = useInView({ triggerOnce: true });
  const [networkingRef, networkingInView] = useInView({ triggerOnce: true });
  const [toolsRef, toolsInView] = useInView({ triggerOnce: true });
  const [inspirationalRef, inspirationalInView] = useInView({
    triggerOnce: true,
  });
  const [invitationRef, invitationInView] = useInView({ triggerOnce: true });
  const [contactRef, contactInView] = useInView({ triggerOnce: true });

  const [formData, setFormData] = useState({
    name: "",
    lastname: "",
    position: "",
    phone: "",
    email: "",
    activity: "",
    message: "",
    privacy: false,
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type } = e.target;
    if (type === "checkbox") {
      const { checked } = e.target as HTMLInputElement;
      setFormData({
        ...formData,
        [name]: checked,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });

      // Remove highlight if the field is filled
      if (value) {
        document
          .getElementById(name)
          ?.classList.remove("border-red-500", "border-2");
      }
    }
  };

  const validateEmail = (email: string): boolean => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email);
  };

  const highlightIncompleteFields = () => {
    const fields: Array<keyof typeof formData> = [
      "name",
      "lastname",
      "position",
      "phone",
      "email",
      "activity",
      "message",
      "privacy",
    ];
    fields.forEach((field: keyof typeof formData) => {
      if (!formData[field]) {
        document
          .getElementById(field)
          ?.classList.add("border-red-500", "border-2");
      }
    });
  };

  const clearFormFields = () => {
    setFormData({
      name: "",
      lastname: "",
      position: "",
      phone: "",
      email: "",
      activity: "",
      message: "",
      privacy: false,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Validate form fields
    if (
      !formData.name ||
      !formData.lastname ||
      !formData.position ||
      !formData.phone ||
      !formData.email ||
      !formData.activity ||
      !formData.message
    ) {
      highlightIncompleteFields();
      MySwal.fire({
        icon: "warning",
        title: "Campos incompletos",
        text: "Por favor, complete todos los campos del formulario.",
        confirmButtonColor: "#5a6d86",
      });
      return;
    }

    // Validate email format
    if (!validateEmail(formData.email)) {
      MySwal.fire({
        icon: "warning",
        title: "Correo electrónico inválido",
        text: "Por favor, ingrese un correo electrónico válido.",
        confirmButtonColor: "#5a6d86",
      });
      return;
    }

    // Validate privacy policy checkbox
    if (!formData.privacy) {
      MySwal.fire({
        icon: "warning",
        title: "Política de Privacidad",
        text: "Debe aceptar la Política de Privacidad para continuar.",
        confirmButtonColor: "#5a6d86",
      });
      return;
    }

    try {
      const response = await axios.post("/api/send-email", formData);
      if (response.status === 200) {
        MySwal.fire({
          icon: "success",
          title: "Mensaje enviado",
          text: "Su mensaje ha sido enviado exitosamente. Espere pronto una respuesta de nuestro equipo.",
          confirmButtonColor: "#5a6d86",
        });
        clearFormFields();
      } else {
        MySwal.fire({
          icon: "error",
          title: "Error",
          text: "Hubo un error al enviar el mensaje. Intente nuevamente más tarde.",
          confirmButtonColor: "#5a6d86",
        });
      }
    } catch (error) {
      console.error("Error al enviar el mensaje:", error);
      MySwal.fire({
        icon: "error",
        title: "Error",
        text: "Hubo un error al enviar el mensaje. Intente nuevamente más tarde.",
        confirmButtonColor: "#5a6d86",
      });
    }
  };

  return (
    <main>
      {/* Hero Section */}
      <section
        id="hero"
        ref={heroRef}
        className="relative w-full flex flex-col justify-center items-start min-h-screen"
      >
        <div className="relative w-full h-screen">
          <picture>
            <source
              srcSet="
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716831197/hero_home_f69ca4cef4.webp 480w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716831197/hero_home_f69ca4cef4.webp 800w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716831197/hero_home_f69ca4cef4.webp 1200w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1716831197/hero_home_f69ca4cef4.webp 1600w"
              sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
              type="image/webp"
            />
            <source
              srcSet="
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716831197/hero_home_f69ca4cef4.jpg 480w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716831197/hero_home_f69ca4cef4.jpg 800w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716831197/hero_home_f69ca4cef4.jpg 1200w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1716831197/hero_home_f69ca4cef4.jpg 1600w"
              sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
              type="image/jpeg"
            />
            <img
              src="https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/v1716831197/hero_home_f69ca4cef4.jpg"
              alt="Hero Home"
              loading="lazy"
              className="absolute inset-0 object-cover w-full h-full"
            />
          </picture>

          <div className="absolute inset-0 bg-gradient-to-b from-[rgba(43,34,39,1.0)] via-[rgba(43,34,39,0.51)] to-[rgba(43,34,39,0.2)]"></div>

          <motion.div
            className="absolute inset-0 flex flex-col items-start justify-center text-white p-6 sm:p-12 md:p-20 lg:p-40 z-10 max-w-4xl"
            initial={{ opacity: 0, scale: 0.9 }}
            animate={heroInView ? { opacity: 1, scale: 1 } : {}}
            transition={{ duration: 0.8, ease: "easeOut" }}
          >
            <p
              className="mt-4 text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold"
              style={{ textShadow: "1px 1px 4px rgba(0, 0, 0, 0.5)" }}
            >
              Uniendo mentes valiosas, construyendo el futuro empresarial:
              <br />
            </p>
            <p
              className="mt-4 text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold"
              style={{ textShadow: "1px 1px 4px rgba(0, 0, 0, 0.5)" }}
            >
              ¡CCED te da la bienvenida!
            </p>
            <Link to="/galeria">
              <button className="mt-6 px-4 py-2 sm:px-6 sm:py-3 bg-[#B9C5D5] text-black font-bold rounded-full shadow-lg transition duration-150 ease-in-out transform hover:bg-[#9bc5d5] hover:scale-105 hover:shadow-2xl">
                Conocer más
              </button>
            </Link>
          </motion.div>
        </div>

        <div
          id="contact-buttons"
          className="fixed bottom-4 right-2 py-4 mr-1 pb-16 flex flex-col items-end space-y-4 z-40"
        >
          <a
            href="https://wa.me/your-number"
            className="group flex items-center text-white hover:text-green-500"
          >
            <span className="hidden group-hover:block p-2 mr-2 bg-[#B9C5D5] text-white bg-opacity-80 rounded-full transform group-hover:translate-x-0  drop-shadow-2xl transition-all duration-300 ease-in-out opacity-0 group-hover:opacity-100 text-shadow-lg">
              Contáctanos
            </span>
            <FontAwesomeIcon
              icon={faWhatsapp}
              style={{
                color: "white",
                backgroundColor: "#7EC16A",
                borderRadius: "50%",
                padding: "12px",
              }}
              className="lg:text-3xl text-lg drop-shadow-2xl"
            />
          </a>
          <Link
            to="/insights"
            className="group flex items-center text-white hover:text-blue-500"
          >
            <span className="hidden group-hover:block p-2 mr-2 bg-[#B9C5D5] bg-opacity-80 text-white rounded-full transform group-hover:translate-x-0  drop-shadow-2xl transition-all duration-300 ease-in-out opacity-0 group-hover:opacity-100 text-shadow-lg">
              Visita nuestro blog
            </span>
            <FontAwesomeIcon
              icon={faMessage}
              style={{
                color: "white",
                backgroundColor: "#B9C5D5",
                borderRadius: "50%",
                padding: "12px",
              }}
              className="lg:text-3xl text-lg drop-shadow-xl"
            />
          </Link>
        </div>
      </section>
      <div className="relative z-30">
        {/* Community Section */}
        <section
          id="community"
          ref={communityRef}
          className="text-white py-6 sm:py-12 md:py-16 lg:py-20 px-6 sm:px-12 md:px-16 lg:px-20 justify-center w-full overflow-hidden"
        >
          <motion.h1
            className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center font-bold"
            initial={{ opacity: 0, scale: 0.9 }}
            animate={communityInView ? { opacity: 1, scale: 1 } : {}}
            transition={{ duration: 0.8, ease: "easeOut" }}
          >
            Forma parte de nuestra comunidad:
          </motion.h1>
        </section>

        {/* Multi-Company Counseling Section */}
        <section
          id="consejeria"
          ref={counselingRef}
          className="flex flex-col lg:flex-row pb-8 lg:pb-16 overflow-hidden "
        >
          <motion.div
            className="flex flex-col justify-center items-center w-full lg:w-1/2 relative py-12 px-8 sm:p-8 md:p-12 lg:p-16"
            initial={{ opacity: 0, x: "100%" }}
            animate={counselingInView ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 1.2, ease: "easeOut" }}
          >
            <div className="absolute inset-0 bg-cover bg-[#5A6D86]"></div>
            <div className="relative z-10">
              <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl text-white font-bold pb-5">
                Consejería Multiempresa
              </h2>
              <p className="text-white text-sm sm:text-base md:text-lg lg:text-xl">
                Ofrecemos un espacio de intercambio, donde los líderes
                empresariales pueden compartir sus inquietudes, experiencias y
                lecciones aprendidas. Fomentando el aprendizaje mutuo y
                proporcionando información valiosa para la toma de decisiones
                estratégicas en sus empresas.
              </p>
            </div>
          </motion.div>
          <div className="relative w-full lg:w-1/2 flex justify-start">
            <picture>
              <source
                srcSet="
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716599604/home_1_f9a31b7b8b.webp 480w,
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716599604/home_1_f9a31b7b8b.webp 800w,
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716599604/home_1_f9a31b7b8b.webp 1200w,
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1716599604/home_1_f9a31b7b8b.webp 1600w"
                sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                type="image/webp"
              />
              <source
                srcSet="
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716599604/home_1_f9a31b7b8b.png 480w,
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716599604/home_1_f9a31b7b8b.png 800w,
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716599604/home_1_f9a31b7b8b.png 1200w,
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1716599604/home_1_f9a31b7b8b.png 1600w"
                sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                type="image/png"
              />
              <img
                src="https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/v1716599604/home_1_f9a31b7b8b.png"
                alt="Foto de la misión"
                loading="lazy"
                className="w-full lg:w-4/5 h-auto"
              />
            </picture>
          </div>
        </section>

        {/* News and Trends Section */}
        <section
          id="noticias-tendencias"
          ref={newsRef}
          className="flex flex-col lg:flex-row pb-8 lg:pb-16 overflow-hidden"
        >
          <div className="hidden lg:flex relative lg:w-1/2 justify-end ">
            <picture className="w-full lg:w-4/5 h-auto z-10">
              <source
                srcSet="
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716599603/home_2_d8af16ef61.webp 480w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716599603/home_2_d8af16ef61.webp 800w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716599603/home_2_d8af16ef61.webp 1200w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1716599603/home_2_d8af16ef61.webp 1600w"
                sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                type="image/webp"
              />
              <source
                srcSet="
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716599603/home_2_d8af16ef61.png 480w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716599603/home_2_d8af16ef61.png 800w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716599603/home_2_d8af16ef61.png 1200w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1716599603/home_2_d8af16ef61.png 1600w"
                sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                type="image/png"
              />
              <img
                src="https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/v1716599603/home_2_d8af16ef61.png"
                alt="Foto de la misión"
                className="w-full h-auto"
                loading="lazy"
              />
            </picture>
          </div>

          <motion.div
            className="flex flex-col justify-center items-center w-full lg:w-1/2 relative py-12 px-8 sm:p-8 md:p-12 lg:p-16 z-0"
            initial={{ opacity: 0, x: "-100%" }}
            animate={newsInView ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 1.2, ease: "easeOut" }}
          >
            <div className="absolute inset-0 bg-cover bg-[#B9C5D5] z-0"></div>
            <div className="relative z-10">
              <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl text-black font-bold pb-5">
                Noticias y Tendencias
              </h2>
              <p className="text-black text-sm sm:text-base md:text-lg lg:text-xl">
                Mantenemos a nuestros miembros actualizados con noticias y
                tendencias regionales relevantes. Nuestro objetivo es
                proporcionar información que guíe en la toma de decisiones y
                permita identificar oportunidades.
              </p>
            </div>
          </motion.div>
          <div className="flex lg:hidden relative w-full justify-end">
            <picture>
              <source
                srcSet="
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_320/v1716599603/home_2_d8af16ef61.webp 320w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_640/v1716599603/home_2_d8af16ef61.webp 640w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_960/v1716599603/home_2_d8af16ef61.webp 960w"
                sizes="(max-width: 320px) 320px, (max-width: 640px) 640px, 960px"
                type="image/webp"
              />
              <source
                srcSet="
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_320/v1716599603/home_2_d8af16ef61.png 320w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_640/v1716599603/home_2_d8af16ef61.png 640w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_960/v1716599603/home_2_d8af16ef61.png 960w"
                sizes="(max-width: 320px) 320px, (max-width: 640px) 640px, 960px"
                type="image/png"
              />
              <img
                src="https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/v1716599603/home_2_d8af16ef61.png"
                alt="Foto de la misión"
                className="w-full lg:w-4/5 h-auto"
                loading="lazy"
              />
            </picture>
          </div>
        </section>

        {/* Courses and Seminars Section */}
        <section
          id="cursos-seminarios"
          ref={coursesRef}
          className="flex flex-col lg:flex-row pb-8 lg:pb-16 overflow-hidden"
        >
          <motion.div
            className="flex flex-col justify-center items-center w-full lg:w-1/2 relative py-12 px-8 sm:p-8 md:p-12 lg:p-16"
            initial={{ opacity: 0, x: "100%" }}
            animate={coursesInView ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 1.2, ease: "easeOut" }}
          >
            <div className="absolute inset-0 bg-cover bg-[#597276]"></div>
            <div className="relative z-10">
              <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl text-white font-bold pb-5">
                Cursos & Seminarios
              </h2>
              <p className="text-white text-sm sm:text-base md:text-lg lg:text-xl">
                Ofrecemos una amplia variedad de cursos, seminarios y
                capacitaciones diseñados para impulsar el desarrollo personal y
                profesional de nuestros miembros. Potenciando el desempeño,
                fomentando una cultura de crecimiento y aprendizaje continuo.
              </p>
            </div>
          </motion.div>
          <div className="relative w-full lg:w-1/2 flex justify-start">
            <picture>
              <source
                srcSet="
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716599618/home_3_92cd3dd6f0.webp 480w,
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716599618/home_3_92cd3dd6f0.webp 800w,
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716599618/home_3_92cd3dd6f0.webp 1200w,
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1716599618/home_3_92cd3dd6f0.webp 1600w"
                sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                type="image/webp"
              />
              <source
                srcSet="
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716599618/home_3_92cd3dd6f0.png 480w,
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716599618/home_3_92cd3dd6f0.png 800w,
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716599618/home_3_92cd3dd6f0.png 1200w,
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1716599618/home_3_92cd3dd6f0.png 1600w"
                sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                type="image/png"
              />
              <img
                src="https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/v1716599618/home_3_92cd3dd6f0.png"
                alt="Foto de la misión"
                className="w-full lg:w-4/5 h-auto"
                loading="lazy"
              />
            </picture>
          </div>
        </section>

        {/* LinkUp Section */}
        <section
          id="linkup"
          ref={linkUpRef}
          className="flex flex-col lg:flex-row pb-8 lg:pb-16 overflow-hidden"
        >
          <div className="hidden lg:flex relative lg:w-1/2 justify-end">
            <picture className="w-full lg:w-4/5 h-auto z-10">
              <source
                srcSet="
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716599604/home_4_1904005c27.webp 480w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716599604/home_4_1904005c27.webp 800w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716599604/home_4_1904005c27.webp 1200w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1716599604/home_4_1904005c27.webp 1600w"
                sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                type="image/webp"
              />
              <source
                srcSet="
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716599604/home_4_1904005c27.png 480w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716599604/home_4_1904005c27.png 800w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716599604/home_4_1904005c27.png 1200w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1716599604/home_4_1904005c27.png 1600w"
                sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                type="image/png"
              />
              <img
                src="https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/v1716599604/home_4_1904005c27.png"
                alt="Foto de la misión"
                className="w-full h-auto"
                loading="lazy"
              />
            </picture>
          </div>

          <motion.div
            className="flex flex-col justify-center bg-[#32444E] items-center w-full lg:w-1/2 relative py-12 px-8 sm:p-8 md:p-12 lg:p-16 z-0"
            initial={{ opacity: 0, x: "-100%" }}
            animate={linkUpInView ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 1.2, ease: "easeOut" }}
          >
            <div className="relative z-10">
              <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl text-white font-bold pb-5">
                LinkUp
              </h2>
              <p className="text-white text-sm sm:text-base md:text-lg lg:text-xl">
                Facilitamos la conexión entre nuestros miembros a través de
                nuestra plataforma integral, que alberga perfiles detallados de
                cada uno. Está diseñada para simplificar el proceso de
                networking y fomentar oportunidades colaborativas dentro de
                nuestra red empresarial.
              </p>
            </div>
          </motion.div>
          <div className="flex lg:hidden relative w-full justify-end">
            <picture>
              <source
                srcSet="
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_320/v1716599604/home_4_1904005c27.webp 320w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716599604/home_4_1904005c27.webp 480w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_640/v1716599604/home_4_1904005c27.webp 640w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716599604/home_4_1904005c27.webp 800w"
                sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, 800px"
                type="image/webp"
              />
              <source
                srcSet="
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_320/v1716599604/home_4_1904005c27.png 320w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716599604/home_4_1904005c27.png 480w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_640/v1716599604/home_4_1904005c27.png 640w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716599604/home_4_1904005c27.png 800w"
                sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, 800px"
                type="image/png"
              />
              <img
                src="https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/v1716599604/home_4_1904005c27.png"
                alt="Foto de la misión"
                className="w-full h-auto"
                loading="lazy"
              />
            </picture>
          </div>
        </section>

        {/* Networking Section */}
        <section
          id="networking"
          ref={networkingRef}
          className="flex flex-col lg:flex-row pb-8 lg:pb-16 overflow-hidden "
        >
          <motion.div
            className="flex flex-col justify-center items-center w-full lg:w-1/2 relative py-12 px-8 sm:p-8 md:p-12 lg:p-16"
            initial={{ opacity: 0, x: "100%" }}
            animate={networkingInView ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 1.2, ease: "easeOut" }}
          >
            <div className="absolute inset-0 bg-cover"></div>
            <div className="absolute inset-0 bg-opacity-80 bg-[#2B2227]"></div>
            <div className="relative z-10">
              <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl text-white font-bold pb-5">
                Networking
              </h2>
              <p className="text-white text-sm sm:text-base md:text-lg lg:text-xl">
                Nos esforzamos por crear experiencias de networking
                excepcionales que fortalezcan los lazos entre nuestros miembros.
                Impulsando el éxito profesional y personal de cada individuo en
                nuestra comunidad, promoviendo relaciones sólidas y sinérgicas.
              </p>
            </div>
          </motion.div>
          <div className="relative w-full lg:w-1/2 flex justify-start">
            <picture>
              <source
                srcSet="
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716599605/home_6_4a99465469.webp 480w,
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716599605/home_6_4a99465469.webp 800w,
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716599605/home_6_4a99465469.webp 1200w,
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1716599605/home_6_4a99465469.webp 1600w"
                sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                type="image/webp"
              />
              <source
                srcSet="
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716599605/home_6_4a99465469.png 480w,
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716599605/home_6_4a99465469.png 800w,
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716599605/home_6_4a99465469.png 1200w,
        https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1716599605/home_6_4a99465469.png 1600w"
                sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                type="image/png"
              />
              <img
                src="https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/v1716599605/home_6_4a99465469.png"
                alt="Foto de la misión"
                className="w-full lg:w-4/5 h-auto"
                loading="lazy"
              />
            </picture>
          </div>
        </section>

        {/* Tools Section */}
        <section
          id="herramientas"
          ref={toolsRef}
          className="flex flex-col lg:flex-row pb-8 lg:pb-16 overflow-hidden"
        >
          <div className="hidden lg:flex relative lg:w-1/2 justify-end">
            <picture className="w-full lg:w-4/5 h-auto z-10">
              <source
                srcSet="
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716599612/home_7_8fb0853307.webp 480w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716599612/home_7_8fb0853307.webp 800w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716599612/home_7_8fb0853307.webp 1200w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1716599612/home_7_8fb0853307.webp 1600w"
                sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                type="image/webp"
              />
              <source
                srcSet="
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716599612/home_7_8fb0853307.png 480w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716599612/home_7_8fb0853307.png 800w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716599612/home_7_8fb0853307.png 1200w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1716599612/home_7_8fb0853307.png 1600w"
                sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                type="image/png"
              />
              <img
                src="https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/v1716599612/home_7_8fb0853307.png"
                alt="Foto de la misión"
                className="w-full h-auto"
                loading="lazy"
              />
            </picture>
          </div>

          <motion.div
            className="flex flex-col justify-center bg-[#D8CCB6] items-center w-full lg:w-1/2 relative py-12 px-8 sm:p-8 md:p-12 lg:p-16 z-0"
            initial={{ opacity: 0, x: "-100%" }}
            animate={toolsInView ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 1.2, ease: "easeOut" }}
          >
            <div className="relative z-10">
              <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl text-black font-bold pb-5">
                Herramientas
              </h2>
              <p className="text-black text-sm sm:text-base md:text-lg lg:text-xl">
                Proporcionamos herramientas prácticas y recursos, como formatos,
                plantillas y guías, para agilizar responsabilidades
                empresariales. Facilitando el camino hacia el éxito empresarial,
                ofreciendo contenido efectivo y útil para nuestros miembros.
              </p>
            </div>
          </motion.div>
          <div className="flex lg:hidden relative w-full justify-end">
            <picture>
              <source
                srcSet="
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_320/v1716599612/home_7_8fb0853307.webp 320w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716599612/home_7_8fb0853307.webp 480w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_640/v1716599612/home_7_8fb0853307.webp 640w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716599612/home_7_8fb0853307.webp 800w"
                sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, 800px"
                type="image/webp"
              />
              <source
                srcSet="
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_320/v1716599612/home_7_8fb0853307.png 320w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716599612/home_7_8fb0853307.png 480w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_640/v1716599612/home_7_8fb0853307.png 640w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716599612/home_7_8fb0853307.png 800w"
                sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 640px) 640px, 800px"
                type="image/png"
              />
              <img
                src="https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/v1716599612/home_7_8fb0853307.png"
                alt="Foto de la misión"
                className="w-full h-auto"
                loading="lazy"
              />
            </picture>
          </div>
        </section>

        {/* Inspirational Section */}
        <section
          id="inspirational"
          ref={inspirationalRef}
          className="relative mt-16 p-6 sm:p-12 md:p-20 lg:p-28 justify-center w-full"
        >
          <picture>
            <source
              srcSet="
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716600127/image_10_73b84a13bf.webp 480w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716600127/image_10_73b84a13bf.webp 800w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716600127/image_10_73b84a13bf.webp 1200w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1716600127/image_10_73b84a13bf.webp 1600w"
              sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
              type="image/webp"
            />
            <source
              srcSet="
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716600127/image_10_73b84a13bf.png 480w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716600127/image_10_73b84a13bf.png 800w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716600127/image_10_73b84a13bf.png 1200w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1716600127/image_10_73b84a13bf.png 1600w"
              sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
              type="image/png"
            />
            <img
              src="https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/v1716600127/image_10_73b84a13bf.png"
              alt="Inspirational Background"
              className="absolute inset-0 z-0 w-full h-full object-cover"
              loading="lazy"
            />
          </picture>

          <div className="absolute inset-0 bg-opacity-40 bg-[#5A6D86]"></div>
          <motion.h1
            className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-center font-bold text-white mt-6 sm:mt-8 md:mt-10 lg:mt-10 relative z-10"
            initial={{ opacity: 0, y: 50 }}
            animate={inspirationalInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.8, ease: "easeOut" }}
            style={{ textShadow: "1px 1px 4px rgba(0, 0, 0, 0.5)" }}
          >
            Donde cada voz encuentra su eco y cada idea su lugar.
          </motion.h1>
        </section>

        {/* About LinkUp Section */}
        <section id="about-linkup" className="relative w-full">
          <div className="absolute right-0 top-0 w-full lg:w-8/12 h-3/5 bg-[#B9C5D5] opacity-10 z-10"></div>

          <div className="block lg:hidden mx-4 sm:mx-8 md:mx-16 pt-10">
            <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold text-[#B9C5D5] text-center">
              Acerca
            </h1>
            <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold text-[#B9C5D5] text-center">
              de <span className="font-extrabold">LinkUp</span>
            </h1>
          </div>

          <div className="flex flex-col-reverse lg:flex-row gap-0 z-40 relative mx-4 sm:mx-8 md:mx-16 lg:mx-40 pt-10 lg:pt-25">
            <div className="flex-1 lg:flex lg:flex-col items-center lg:items-start mb-10 lg:mb-0">
              <h1 className="hidden pt-16 lg:block text-3xl sm:text-4xl md:text-5xl font-bold text-[#B9C5D5] mt-14 text-center lg:text-left lg:text-7xl">
                Acerca
              </h1>
              <h1 className="hidden lg:block text-3xl sm:text-4xl md:text-5xl font-bold text-[#B9C5D5] text-center lg:text-left lg:text-7xl">
                de <span className="font-extrabold">LinkUp</span>
              </h1>
              <picture>
                <source
                  srcSet="
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716600191/home_lu_1_ca20e044c2.webp 480w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716600191/home_lu_1_ca20e044c2.webp 800w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716600191/home_lu_1_ca20e044c2.webp 1200w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1716600191/home_lu_1_ca20e044c2.webp 1600w"
                  sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                  type="image/webp"
                />
                <source
                  srcSet="
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716600191/home_lu_1_ca20e044c2.png 480w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716600191/home_lu_1_ca20e044c2.png 800w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716600191/home_lu_1_ca20e044c2.png 1200w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1716600191/home_lu_1_ca20e044c2.png 1600w"
                  sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                  type="image/png"
                />
                <img
                  src="https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/v1716600191/home_lu_1_ca20e044c2.png"
                  alt="Imagen descriptiva"
                  className=" lg:pt-32 w-full lg:w-auto"
                  loading="lazy"
                />
              </picture>
              <h2 className="text-lg sm:text-xl font-semibold text-white mt-10 text-center ">
                Crea conexiones estratégicas
              </h2>
            </div>

            <div className="flex-1 lg:flex lg:flex-col items-center lg:items-start pt-10 lg:pt-96 mb-10 lg:mb-0">
              <div className="pt-0 lg:pt-96"></div>
              <picture>
                <source
                  srcSet="
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716600193/home_lu_2_907caf3019.webp 480w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716600193/home_lu_2_907caf3019.webp 800w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716600193/home_lu_2_907caf3019.webp 1200w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1716600193/home_lu_2_907caf3019.webp 1600w"
                  sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                  type="image/webp"
                />
                <source
                  srcSet="
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716600193/home_lu_2_907caf3019.png 480w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716600193/home_lu_2_907caf3019.png 800w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716600193/home_lu_2_907caf3019.png 1200w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1716600193/home_lu_2_907caf3019.png 1600w"
                  sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                  type="image/png"
                />
                <img
                  src="https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/v1716600193/home_lu_2_907caf3019.png"
                  alt="Imagen descriptiva"
                  className="w-full lg:w-auto"
                  loading="lazy"
                />
              </picture>
              <h2 className="text-lg sm:text-xl font-semibold text-white mt-10 text-center">
                Networking automatizado
              </h2>
            </div>

            <div className="flex-1 md:pt-24 lg:flex lg:flex-col items-center lg:items-start">
              <p className="text-sm sm:text-base md:text-lg text-[#D8CCB6] text-center lg:text-left">
                <strong className="font-bold text-[#D8CCB6]">
                  LinkUp es la plataforma de networking inteligente
                </strong>{" "}
                que revoluciona la forma en que nuestros miembros se conectan y
                colaboran. Con LinkUp, la creación de conexiones estratégicas
                nunca ha sido más fácil ni más efectiva. Cada miembro cuenta con
                un perfil detallado que incluye información específica sobre
                habilidades, experiencias, conocimientos y proyectos pasados y
                presentes.
              </p>
              <div className="lg:pt-2"></div>
              <picture>
                <source
                  srcSet="
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716600199/home_lu_3_dea0da7ac1.webp 480w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716600199/home_lu_3_dea0da7ac1.webp 800w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716600199/home_lu_3_dea0da7ac1.webp 1200w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1716600199/home_lu_3_dea0da7ac1.webp 1600w"
                  sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                  type="image/webp"
                />
                <source
                  srcSet="
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716600199/home_lu_3_dea0da7ac1.png 480w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716600199/home_lu_3_dea0da7ac1.png 800w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716600199/home_lu_3_dea0da7ac1.png 1200w,
          https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1716600199/home_lu_3_dea0da7ac1.png 1600w"
                  sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
                  type="image/png"
                />
                <img
                  src="https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/v1716600199/home_lu_3_dea0da7ac1.png"
                  alt="Imagen descriptiva"
                  className="pt-10 lg:pt-28 w-full lg:w-auto"
                  loading="lazy"
                />
              </picture>
              <h2 className="text-lg sm:text-xl font-semibold text-white mt-10 text-center ">
                Promueve el intercambio de conocimiento
              </h2>
            </div>
          </div>
        </section>

        {/* Invitation Section */}
        <section
          id="invitation"
          ref={invitationRef}
          className="flex flex-col lg:flex-row w-full mt-10 lg:mt-20 pb-12 lg:pb-24"
        >
          <motion.div
            className="w-full lg:w-2/4 mb-10 lg:mb-0 lg:mr-4 bg-[#32444E] p-10 lg:p-20 relative"
            initial={{ opacity: 0, y: 50 }}
            animate={invitationInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.8, ease: "easeOut" }}
          >
            <p className="text-4xl sm:text-6xl md:text-8xl font-bold text-[#B9C5D5]">
              Te invitamos a ser parte del CCED
            </p>
            <img
              src="/static/capa10.png"
              alt="Valores 2"
              className="absolute right-0 bottom-0"
              style={{ width: "200%", height: "auto" }}
              loading="lazy"
            />
          </motion.div>
          <motion.div
            className="w-full lg:w-3/4 lg:mx-6"
            initial={{ opacity: 0, y: 50 }}
            animate={invitationInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.8, ease: "easeOut" }}
          >
            <div className="text-base sm:text-lg bg-[rgba(90,96,104,0.1)] justify-center px-6 sm:px-12 md:px-24 lg:px-32 py-12 sm:py-16 md:py-24 text-[#D8CCB6]">
              <p className="pb-6">
                <strong className="font-bold text-[#D8CCB6]">
                  En el CCED,
                </strong>{" "}
                creemos en el poder de la colaboración y el intercambio de
                conocimientos para impulsar el éxito empresarial.{" "}
                <strong className="font-bold text-[#D8CCB6]">
                  Si eres un empresario, consejero o director comprometido con
                  el crecimiento de tu empresa
                </strong>{" "}
                y deseas formar parte de una comunidad dinámica y en constante
                evolución, te invitamos a unirte a nosotros.
              </p>
              <p className="pb-6">
                Descubre cómo nuestras sesiones de Consejo, eventos exclusivos,
                plataforma LinkUp y recursos personalizados pueden potenciar tu
                liderazgo y abrir nuevas oportunidades para tu empresa.
              </p>
              <p className="text-lg sm:text-xl md:text-2xl">
                Únete al CCED hoy y{" "}
                <strong className="font-bold text-[#D8CCB6]">
                  forma parte de una red de líderes visionarios que están
                  transformando el panorama empresarial en la región.
                </strong>{" "}
              </p>
            </div>
            <div className="pt-10 text-lg sm:text-2xl md:text-3xl bg-[#2b2227] flex justify-center items-center text-[#D8CCB6] font-bold text-center lg:text-left">
              <p>¡Es hora de alcanzar nuevos niveles de éxito juntos!</p>
            </div>
          </motion.div>
        </section>

        {/* Contact Section */}
        <section
          id="contact"
          ref={contactRef}
          className="relative w-full h-auto flex flex-col lg:flex-row p-6 sm:p-10 lg:p-20"
        >
          <picture>
            <source
              srcSet="
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716600328/home_form_3aaf414ffc.webp 480w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716600328/home_form_3aaf414ffc.webp 800w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716600328/home_form_3aaf414ffc.webp 1200w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1716600328/home_form_3aaf414ffc.webp 1600w"
              sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
              type="image/webp"
            />
            <source
              srcSet="
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_480/v1716600328/home_form_3aaf414ffc.png 480w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_800/v1716600328/home_form_3aaf414ffc.png 800w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1200/v1716600328/home_form_3aaf414ffc.png 1200w,
      https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto,w_1600/v1716600328/home_form_3aaf414ffc.png 1600w"
              sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1600px"
              type="image/png"
            />
            <img
              src="https://res.cloudinary.com/dhoyyqszi/image/upload/f_auto,q_auto/v1716600328/home_form_3aaf414ffc.png"
              alt="Background"
              className="absolute inset-0 w-full h-full object-cover z-0"
              loading="lazy"
            />
          </picture>

          <div className="absolute inset-0 bg-opacity-55 bg-[#32444E] z-0"></div>

          <motion.div
            className="flex-1 flex flex-col justify-center z-10 mb-10 lg:mb-0"
            initial={{ opacity: 0, y: 50 }}
            animate={contactInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.8, ease: "easeOut" }}
          >
            <div className="p-6 sm:p-10 lg:p-12 bg-[rgba(50,68,78,0.5)] rounded-3xl shadow-lg">
              <h1 className="text-2xl sm:text-3xl font-bold mb-6 text-white">
                Contáctanos
              </h1>
              <form onSubmit={handleSubmit} className="text-white">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <div className="mb-3">
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Nombre"
                      className="w-full border rounded-3xl py-3 px-5 bg-transparent leading-tight focus:outline-none focus:shadow-outline placeholder-gray-100 placeholder-opacity-100"
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="text"
                      id="lastname"
                      name="lastname"
                      value={formData.lastname}
                      onChange={handleChange}
                      placeholder="Apellido"
                      className="w-full border rounded-3xl py-3 px-5 bg-transparent leading-tight focus:outline-none focus:shadow-outline placeholder-gray-100 placeholder-opacity-100"
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="text"
                      id="position"
                      name="position"
                      value={formData.position}
                      onChange={handleChange}
                      placeholder="Empresa"
                      className="w-full border rounded-3xl py-3 px-5 bg-transparent leading-tight focus:outline-none focus:shadow-outline placeholder-gray-100 placeholder-opacity-100"
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="tel"
                      id="phone"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder="Teléfono"
                      className="w-full border rounded-3xl py-3 px-5 bg-transparent leading-tight focus:outline-none focus:shadow-outline placeholder-gray-100 placeholder-opacity-100"
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Correo electrónico"
                    className="w-full border rounded-3xl py-3 px-5 bg-transparent leading-tight focus:outline-none focus:shadow-outline placeholder-gray-100 placeholder-opacity-100"
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    id="activity"
                    name="activity"
                    value={formData.activity}
                    onChange={handleChange}
                    placeholder="Actividad económica"
                    className="w-full border rounded-3xl py-3 px-5 bg-transparent leading-tight focus:outline-none focus:shadow-outline placeholder-gray-100 placeholder-opacity-100"
                  />
                </div>
                <div className="mb-3">
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Mensaje"
                    rows={4}
                    className="w-full border rounded-3xl py-5 px-5 bg-transparent leading-tight focus:outline-none focus:shadow-outline placeholder-gray-100 placeholder-opacity-100"
                  ></textarea>
                </div>
                <div className="mb-4">
                  <input
                    type="checkbox"
                    id="privacy"
                    name="privacy"
                    checked={formData.privacy}
                    onChange={handleChange}
                    className="mr-2"
                  />
                  <label htmlFor="privacy">
                    He leído y acepto la{" "}
                    <span className="underline">Política de Privacidad</span>
                  </label>
                </div>
                <button
                  type="submit"
                  className="w-full lg:w-auto float-right bg-[#B9C5D5] text-[#2B2227] font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline"
                >
                  Enviar
                </button>
              </form>
            </div>
          </motion.div>

          <motion.div
            className="flex-1 flex items-center justify-center p-6 sm:p-10 lg:p-20 text-center text-white z-10"
            initial={{ opacity: 0, y: 50 }}
            animate={contactInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.8, ease: "easeOut" }}
          >
            <p
              className="text-2xl sm:text-3xl md:text-4xl"
              style={{ textShadow: "1px 1px 4px rgba(0, 0, 0, 0.5)" }}
            >
              Encuentra <span className="font-bold">conexiones</span>{" "}
              <span className="font-bold">estratégicas</span> en un instante con{" "}
              <span className="font-bold text-[#B9C5D5]">LinkUp</span>.
            </p>
          </motion.div>
        </section>
      </div>
    </main>
  );
};

export default Home;
