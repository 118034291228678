import React from "react";
import { Link } from "react-router-dom";

/**
 * NotFound component that displays a 404 error message and a link to the homepage.
 */
const NotFound: React.FC = () => {
  return (
    <div
      id="not-found-container"
      className="min-h-screen flex flex-col items-center justify-center bg-[#2B2227] text-white p-4 md:p-8 lg:p-16"
    >
      <h1 id="not-found-title" className="text-5xl md:text-6xl lg:text-7xl font-bold mb-4">
        404
      </h1>
      <p id="not-found-message" className="text-xl md:text-2xl lg:text-3xl mb-8 text-center">
        Lo sentimos, la página que buscas no existe.
      </p>
      <Link to="/">
        <button
          id="not-found-button"
          className="bg-[#B9C5D5] text-black font-bold py-2 px-4 rounded-full transition duration-300 hover:bg-[#597276] text-sm md:text-base lg:text-lg"
        >
          Volver al inicio
        </button>
      </Link>
    </div>
  );
};

export default NotFound;
